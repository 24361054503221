<template>
  <el-dialog customClass="refresh_dialog"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="handleOpened"
  >

    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-31">{{ dialogTitle }}</h5>
    </span>

    <div>

      <h5 style="margin-top: 40px">
        该用户已录入过人员信息,请点击确定
      </h5>

    </div>

    <!-- 弹窗内容 -->
    <span slot="footer" class="dialog-footer">
      <vs-button  size="small"  type="relief"  @click="handleClose">确定</vs-button>
    </span>

  </el-dialog>
</template>

<script>
export default {
  props: ["dialogFormVisible", "dialogFormData"],
  data() {
    return {
      dialogTitle: '提示',
      isVisible: this.dialogFormVisible,
      isDisable: false,
      peopleID: ''
    };
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      //console.log("dialogFormData", val);
      this.peopleID = val.peopleID
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    },
  },
  methods: {
    // 打开弹框完毕回调
    async handleOpened() {
      console.log('打开弹窗')
    },
    // 关闭弹框回调
    handleClose() {
      this.isVisible = false;
       if (this.isVisible == false) {
        this.changeNavBarShadow();
        this.$emit("handleDiaLogClose", {peopleID: this.peopleID});
      }
    },
    // 导航栏阴影问题 组件联动 (true, false)
    changeNavBarShadow(val) {
      var dom = document.getElementById("content-area");
      if (dom) {
        if (val) {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .concat(" ")
              .concat("dialog-show-overlay")
          );
        } else {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .replace(" ", "")
              .replace("dialog-show-overlay", "")
          );
        }
      }
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = "";
      switch (state) {
        case "success":
          title = "成功";
          color = "success";
          break;
        case "danger":
          title = "错误";
          color = "danger";
          break;
        case "warning":
          title = "警告";
          color = "warning";
          break;
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
  }
};
</script>

<style lang="scss" scope="scope">
  .refresh_dialog {
    width: 50%;
    min-width: 350px;
    .el-dialog__body {
      margin-top: 30px !important;
    }
  }
</style>
