<template>
  <el-dialog customClass="cardPro_dialog"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="handleOpened"
  >

    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-31">{{ dialogTitle }}</h5>
    </span>

    <div>
      <div style="display: inline-block;">
        <!-- 卡添加 -->
        <el-tooltip content="读卡" placement="top" >
          <div class="el-upload addCard" @click="addCardBtn">
            <i slot="default" class="el-icon-plus"></i>
          </div>
        </el-tooltip>
        <!-- 卡列表 -->
        <ul class="infinite-list" v-loading="drawerLoading">
          <li v-for="(item, index) in cardList" :key="index" class="infinite-list-item" >
            <el-card shadow="hover"  class="box-card">
              <div class="del-icon" @click="delCardFlow(item.card_no)"><i class="el-icon-close"></i></div>
              <div class="text item" @click="editCardFlow(item)">
                <p>{{ _.find(BindTypeArr, {'code': item.binding_type}) ? _.find(BindTypeArr, {'code': item.binding_type}).name : "" }}</p>
                <p>{{ _.find(CardTypeArr, {'code': item.card_type}) ? _.find(CardTypeArr, {'code': item.card_type}).name + ':' + item.card_no : "" }}</p>
                <p>有效时间:{{ item.expire_time }}</p>
              </div>
            </el-card>
          </li>
        </ul>
      </div>
      
      <vs-divider></vs-divider>

      <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top" v-show="isShowForm">

        <el-row :gutter="24">
          <el-col :span="8">
            <!-- 表单认证(卡类型) -->
            <el-form-item label="卡类型" prop="cardType">
              <el-select style="width:100%" v-model="form.cardType" placeholder="请输入卡类型" class="w-100" disabled="disabled">
                <el-option v-for="item in CardTypeArr" :key="item.index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 表单认证(卡号) -->
            <el-form-item label="卡号" prop="cardNo">
              <el-input v-model="form.cardNo"  prefix-icon="el-icon-edit" placeholder="请输入卡号" disabled="disabled"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- 表单认证(卡属性) -->
            <el-form-item label="卡属性" prop="cardAttribute">
              <el-select style="width:100%" v-model="form.cardAttribute" placeholder="请输入卡属性" class="w-100" :disabled="isDisForm"  @change="selectCardAttribute">
                <el-option v-for="item in BindTypeArr" :key="item.index" :label="item.name" :value="item.code"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row :gutter="24">
          <el-col :span="8">
            <!-- 表单认证(有效时间) -->
            <div v-show="!isDisForm" class="endTime_btn" v-for="item of endTimeOptions" :key="item.index">
              <span @click="endimeClick(item.label)" :class="{ 'is-selected': item.active }">{{ item.label }}</span>
            </div>
            <el-form-item label="有效时间" required>
              <el-date-picker style="width: 100%" v-model="form.effectTime" type="date" placeholder="请输入有效时间" :disabled="isDisForm"></el-date-picker>
            </el-form-item>
          </el-col>

        </el-row>

        <h6 v-show="isShowTable" style="font-size: 0.8rem; font-weight: 600; line-height: 100%; margin-bottom: 10px">通行权限</h6>

        <el-table  v-show="isShowTable" border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="55" :selectable='checkBoxTable'></el-table-column>
          <el-table-column label="类型" width="120">
            <template slot-scope="scope">
              <p>{{ _.find(GroupTypeArr, { 'code': scope.row.groupType}) ? _.find(GroupTypeArr, { 'code': scope.row.groupType}).name : "" }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="groupName" label="通行组" show-overflow-tooltip></el-table-column>
        </el-table>

      </el-form>

    </div>

    <!-- 弹窗内容 -->
    <span slot="footer" class="dialog-footer">
      <vs-button  size="small"  type="relief" @click="handleClose" style="margin-right:10px">取消</vs-button>
      <vs-button  size="small"  type="relief" @click="submitForm('ruleForm')" v-if="isShowForm && isSubmitForm">制卡</vs-button>
    </span>

  </el-dialog>
</template>

<script>
export default {
  props: ["dialogFormVisible", "dialogFormData"],
  data() {
    return {
      dialogTitle: '制卡',
      isVisible: this.dialogFormVisible,
      // 渲染数据
      cardList: [],
      // 枚举
      relationReturnDataAll: [],
      GroupTypeArr: [],
      CardStatusArr: [],
      CardTypeArr: [],
      BindTypeArr: [],
      // 加载状态
      drawerLoading: false,
      isDisable: false, 
      isShowForm: false,
      isSubmitForm: false,
      isShowTable: true,
      isDisForm: false,
      // 提交
      form: {
        credentialNo: '',
        villageCode: '',
        peopleID: '',
        cardType: 1,
        cardNo: '',
        cardAttribute: 2,
        effectTime:  '2099-12-31 00:00:00'
      },
      rules: {
        cardType: [
          { required: true, message: '请输入卡类型', trigger: 'blur' }
        ],
        cardNo: [
          { required: true, message: '请输入卡号', trigger: 'blur' }
        ],
        cardAttribute: [
          { required: true, message: '请输入卡属性', trigger: 'blur' }
        ],
        effectTime: [
          { required: false, message: '请输入有效时间', trigger: 'blur' }
        ],
        effectYear: [
          { required: false, message: '请输入有效期', trigger: 'blur' }
        ]
      },
      // table
      tableData: [],
      multipleSelection: [],
      endTimeOptions: [
        {"label": "永久", "active": true},
        {"label": "一年", "active": false},
        {"label": "半年", "active": false},
        {"label": "三个月", "active": false}
      ]
    };
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      // console.log("dialogFormData", val);
      this.reFresh = false;

      // 设备所需参数
      this.form = this.decodeParam(val);

      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true;
      });
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.$cFun.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    },
    'relationReturnDataAll'(val) {
      this.CardStatusArr = _.find(val, { 'using_type': 'db/card/card_status' }).extend;
      this.GroupTypeArr = _.find(val, { 'using_type': 'db/pass_group/group_type' }).extend;
      this.CardTypeArr =  _.find(val, { 'using_type': 'db/card/card_type' }).extend;
      this.BindTypeArr = _.find(val, { 'using_type': 'db/card/binding_type' }).extend;
    },
    'tableData'(val) {
      this.$nextTick(function() {
        this.toggleSelection();
        this.toggleSelection(_.filter(val, { 'hasChoose': 1}));
      });
    },
    async 'form.cardAttribute'(val) {
      // 住户组 2 管理组 3
      console.log("form.cardAttribute 触发修改:", val);
      (val == 2 ) ? await this.getPeopleGroupList(this.form.peopleID, this.form.villageCode) : await this.getManagePG(this.form.villageCode);
    }
  },
  methods: {
    // 卡刷新变化时出发
    async selectCardAttribute(val) {
      console.log("切换卡状态:", val);
      // (val == 2 ) ? await this.getPeopleGroupList(this.form.peopleID, this.form.villageCode) : await this.getManagePG(this.form.villageCode);
    },
    // 表格状态更新
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    // 表格选择事件
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 表格事件
    checkBoxTable(row) {
      if(this.isDisForm){
        return false;//禁用
      }else{
        return true;//不禁用
      }
    },
    // 关闭弹框回调
    handleClose() {
      this.isShowForm = false;
      this.isVisible = false;
       if (this.isVisible == false) {
        this.$cFun.changeNavBarShadow();
        this.$emit("handleDiaLogClose", this.result);
      }
    },
    // 恢复提交参数
    decodeParam(val) {
      let form = JSON.parse(JSON.stringify(this.form));
      
      form.credentialNo = val.credentialNo;
      form.villageCode = val.villageCode;
      form.peopleID = val.peopleID;

      return form;
    },
    // 切换有效期
    endimeClick(value) {
      let arr = []
      this.endTimeOptions.map( item => {
        if(item['label'] === value) {
          item['active'] = true;
          arr.push(item);
        } else {
          item['active'] = false;
          arr.push(item);
        }
      })
      this.endTimeOptions = arr;
      // 赋值
      let date = new Date();
      if (value == "三个月")
        this.form.effectTime = date.setMonth(date.getMonth() + 3);
      if (value == "半年") 
        this.form.effectTime = date.setMonth(date.getMonth() + 6);
      if (value == "一年") 
        this.form.effectTime = date.setMonth(date.getMonth() + 12);
      if (value == "永久") 
        this.form.effectTime = "2099-12-31 00:00:00"
    },
    // 清空表单
    emptyCardForm() {
      try {
        this.$refs.ruleForm.resetFields();
      } catch (e) {

      }  
    },
    // 添加卡按钮
    async addCardBtn() {
      // 还原状态
      this.isShowForm = false;
      this.emptyCardForm();
      // 读取卡
      let isOk = await this.readCardFlow();
      if (!isOk) return false

      // 展示卡信息
      this.isSubmitForm = true;
      this.isShowForm = true;
      this.isDisForm = false;
    },
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget;
      this.$refs[formName].validate( async (valid) => {
        if (valid) {
          if (formName === "ruleForm") {
            await this.writeCardFlow();
          }
        } else {
          return false;
        }
      });
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el);
      }, 1000);
    },
    // 读卡流程
    async readCardFlow() {
      // 调用设备使是否在线
      let isOnline  = await this.deviceIsOnline();
      if (!isOnline) return false
      // 读卡
      let isReadCardOk = await this.readCardNum();
      if (!isReadCardOk )return false
      // 匹配卡
      let matchCardInfo = _.find(this.cardList,  { 'card_no': this.form.cardNo})
      if (matchCardInfo) {
        await this.editCardFlow(matchCardInfo)
      } else {
        // 更新通行组
        this.endimeClick('永久');
        // this.form.cardAttribute = 2;
        await this.getPeopleGroupList(this.form.peopleID, this.form.villageCode)
      }
      return true
    },
    // 写卡流程
    async writeCardFlow() {
      // 调用设备使是否在线
      let isOnline  = await this.deviceIsOnline()
      if (!isOnline) return false
      // 写卡
      let isWriteOk = await this.writeCard();
      if (!isWriteOk) return false
      // 最后调用写卡接口
      let isInsertCardOk = await this.insertOrModifyCardAuth();
      // 关闭弹窗
      if (isInsertCardOk) this.isShowForm = false;
    },
    // 删除卡流程
    async delCardFlow(card_no) {
      // 调用设备使是否在线
      let isOnline  = await this.deviceIsOnline();
      if (!isOnline) return false
      // 读的卡号=删的卡号
      let { state, cardNo } = await this.simpleReadCardNum();
      if (!state) return false
      if (cardNo !== card_no) {
        this.$cFun.showNotify(this.$vs.notify, "warning", '被删除卡号与识别卡号不符');
        return false
      }
      // 清卡
      let isEraseCardOk = await this.eraseCard();
      if (!isEraseCardOk) return false
      // 最后调用删卡接口
      let isDelCardOk = await this.delCard(card_no);
      // 关闭弹窗
      if (isDelCardOk) this.isShowForm = false;
    },
    // 插入或者修改卡和卡授权
    async insertOrModifyCardAuth() {
      try {
        let params = {
          card_no: this.form.cardNo,
          village_code: this.form.villageCode,
          card_type: String(this.form.cardType),
          binding_type: String(this.form.cardAttribute),
          certificate_no: this.form.credentialNo,
          expire_time: this.$cFun.formatTime(this.form.effectTime),
          auths: _.map(this.multipleSelection, o =>  _.mapKeys( _.pick(o, ['pgID']), k => k = 'pg_id'))
        }
        let result = await this.$apis.ODC("插入或者修改卡和卡授权", params);
        if (this.$apis.isOk(result)) {
          await this.getCardList(this.form.villageCode, this.form.credentialNo)
          // await this.getPeopleGroupList(this.form.peopleID, this.form.villageCode)
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          return true
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
          return false
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
        return false
      }
    },
    // 设备在线
    async deviceIsOnline() {
      // try {
      //   let params = {}
      //   let result = await this.$apis.ODC("读取设备状态", params);
      //   if (result == 0) {
      //     return true
      //   } else {
      //     this.$cFun.showNotify(this.$vs.notify, "warning", '读卡设备不在线');
      //     return false
      //   }
      // } catch (err) {
      //   this.$cFun.showNotify(this.$vs.notify, "danger", '读卡设备异常');
      //   return false
      // }
    },
    // 读卡号
    async readCardNum() {
      try {
        let params = {}
        let result = await this.$apis.ODC("读卡号", params);
        result = JSON.parse(result)  || {}
        if (result && result.cardid != '' && ( result.cardtype == 'NFC' || result.cardtype == 'IC' ) && result.cardtype != 'Unknown') {
          let cardType = result.cardtype
          this.form.cardNo =  result.cardid
          this.form.cardType =  _.find(this.CardTypeArr, { 'name': `${cardType}卡`}).code
          return true
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", '未发现卡');
          return false
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", '读卡异常');
        return false
      }
    },
    // 简易读卡号
    async simpleReadCardNum() {
      try {
        let params = {}
        let result = await this.$apis.ODC("读卡号", params);
        result = JSON.parse(result)  || {}
        if (result && result.cardid != '' && ( result.cardtype == 'NFC' || result.cardtype == 'IC' ) && result.cardtype != 'Unknown') {
           return { state: true, cardNo: result.cardid }
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", '未发现卡');
          return { state: false, cardNo: null }
        }
      } catch (err) {
        console.log(err)
        this.$cFun.showNotify(this.$vs.notify, "danger", '读卡异常');
        return { state: false, cardNo: null }
      }
    },
    // 写卡
    async writeCard() {
      try {
        let params = {
          bindingType: this.form.cardAttribute,
          cardNo: this.form.cardNo,
          cardType: this.form.cardType,
          credentialNo: this.form.credentialNo,
          endTime: _.fill(Array(this.multipleSelection.length), this.$cFun.formatTime(this.form.effectTime)),
          functions: [],
          groupInfos: _.map(this.multipleSelection, o =>  o.groupCode )
        }
        let result = await this.$apis.ODC("写卡", JSON.stringify(JSON.stringify(params)));
        if (result == 0) {
          // this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          return true
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", '写卡失败,请重新放置卡片');
          return false
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", '写卡异常');
        return false
      }
    },
    // 清卡
    async eraseCard() {
      try {
        let params = {}
        let result = await this.$apis.ODC("清卡", params);
        if (result == 0) {
          return true
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", '清卡失败,请重新放置卡片');
          return false
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", '清卡异常');
        return false
      }
    },
    // 删除卡和卡授权
    async delCard(card_no) {
      try {
        let params = { card_no: card_no }
        let result = await this.$apis.ODC("删除卡和卡授权", params);
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, "success", this.$apis.getResMessage(result));
          await this.getCardList(this.form.villageCode, this.form.credentialNo);
          return true
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
          return false
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
        return false
      }
    },
    // 编辑卡流程
    async editCardFlow(item) {
      console.log('编辑卡流程', item.binding_type)
      console.log(this.tableData)
      // 修改
      this.form.cardNo = item.card_no;
      this.form.cardType = item.card_type;
      this.form.cardAttribute = item.binding_type;
      this.form.effectTime = item.expire_time;
      // 修改有效期选项
      this.endimeClick('');
      // 获取通行组
      (item.binding_type == 2) ? await this.getPeopleGroupList(this.form.peopleID, this.form.villageCode) : await this.getManagePG(this.form.villageCode);
      // 修改通行权限
      this.multipleSelection = _.filter(this.tableData, o => _.split(item.pg_ids, ',').includes(o.pgID));
      this.tableData = _.cloneDeep(_.forEach(this.tableData, o =>  _.create(o, {'hasChoose': ( _.split(item.pg_ids, ',').includes(o.pgID) ? 1 : 0 )})));
      // 状态
      this.isShowForm = true;
      this.isDisForm = true;
      this.isSubmitForm = false;
    },
    // 打开弹框完毕回调
    async handleOpened() {
      // 获取枚举
      this.relationReturnDataAll = await this.getRelationReturnDataALL(["db/pass_group/group_type","db/card/card_status","db/card/card_type",'db/card/binding_type']);
      // 获取卡列表
      await this.getCardList(this.form.villageCode, this.form.credentialNo)
      // 获取通行权限 (1 已勾 0 可勾)
      // await this.getPeopleGroupList(this.form.peopleID, this.form.villageCode);
    },
    // 获取卡列表
    async getCardList(village_code, certificate_no) {
      this.drawerLoading = true;
      try {
        let param = {
          village_code: village_code,
          certificate_no: certificate_no
        }
        let result = await this.$apis.ODC("获取卡列表", param);
        if (this.$apis.isOk(result)) {
          this.cardList = result.cardList;
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
      this.drawerLoading = false;
    },
    // 获取枚举值(全部)
    async getRelationReturnDataALL(usingTypeArr) {
      try {
        let params = {
          usingTypes: usingTypeArr
        };
        let result = await this.$apis.ODC("获取关系内容", params);
        if (this.$apis.isOk(result)) {
          return  result.relationList
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 获取用户通行组列表 (1 已勾 0 可勾)
    async getPeopleGroupList(peopleID, villageCode) {
      this.tableLoading = true;
      try {
        let param = {
          peopleID: peopleID,
          villageCode: villageCode,
          source: 2
        }
        let result = await this.$apis.ODC("获取用户通行组列表", param);
        if (this.$apis.isOk(result)) {
          this.tableData = result.data;
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
      this.tableLoading = false;
    },
    // 获取管理通行组列表 (1 已勾 0 可勾)
    async getManagePG(villageCode) {
      this.tableLoading = true;
      try {
        let param = {
          village_code: villageCode
        }
        let result = await this.$apis.ODC("获取管理通行组列表", param);
        if (this.$apis.isOk(result)) {
          this.tableData = result.pgList;
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
      this.tableLoading = false;
    },
  },
  mounted() {
    this.toggleSelection(_.filter(this.tableData, { 'hasChoose': 1}));
  }
};
</script>

<style lang="scss" scope="scope">
  .cardPro_dialog {
    width: 50%;
    min-width: 900px;
    .el-dialog__body {
      margin-top: 30px !important;
    }
    .infinite-list {
      display: flex;
      width: 630px;
      overflow-x: auto;
      overflow-y: hidden;
      .infinite-list-item {
        float: left;
      }
    }
    .box-card {
      position: relative;
      width: 250px; 
      height: 120px; 
      margin-right: 20px;
      .del-icon {
        position: absolute;
        text-align: center;
        width: 20px;
        height: 20px;
        top: 5px;
        right: 5px;
        z-index: 99;
        cursor: pointer;   
      }
      .el-card__body {
        height: 100%;
        padding: 0px!important;
        cursor: pointer;
      }
      .item {
        padding: 20px; 
      }
    }
    .addCard{
      float: left;
      margin-right: 20px;
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 150px;
      height: 120px;
      line-height: 120px;
      vertical-align: top;
    }
    .endTime_btn {
      float: right;
      font-size: 0.7rem;
      font-weight: 600;
      line-height: 100%;
      span {
        cursor: pointer;
        border-right: 1px solid #ccc;
        padding: 0 3px;
      }
      .is-selected {
        background-color: rgba(var(--vs-primary), 1) !important;
        color: #fff;
      }
    }
  }
</style>
