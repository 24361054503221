<template>
  <el-dialog
    customClass="video_customDialog"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="handleOpened"
  >
  
    <div class="face-capture" id="face-capture">
      <video ref="refVideo" id="video" autoplay></video>
      <canvas ref="refCanvas" id="canvas" :style="{opacity: 1}"></canvas>
      <canvas ref="refCanvas1" id="canvas1" :style="{opacity: 1}"></canvas>
      <canvas ref="refCanvas2" id="canvas2" :style="{opacity: 0}" width="200" height="285"></canvas>
      <img ref="refImg" id="img"  src="@/assets/images/face/video-cover.png" alt="cover" class="img-cover" :style="{opacity: 0}"/>

      <div class="control-container">
        <h2 class="title">{{scanTip}}</h2>
      </div>
  
    </div>

  </el-dialog>
</template>

<script>
export default {
  props: ["dialogFormVisible"],
  data() {
    return {
      dialogTitle: "人脸识别",
      isVisible: this.dialogFormVisible,
      isDisable: false, 

      a: true,
      recognitionResult: [],
      // 视频
      screenSize: {width: window.screen.width, height: window.screen.height},
      URL: null,
      streamIns: null,        // 视频流
      showContainer: true,    // 显示
      tracker: null,
      tipFlag: false,         // 提示用户已经检测到
      flag: false,            // 判断是否已经拍照
      context: null,          // canvas上下文
      profile: [],            // 轮廓
      removePhotoID: null,    // 停止转换图片
      scanTip: '人脸识别中...', // 提示文字
      imgUrl: ''              // base64格式图片
    };
  },
  watch: {
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    }
  },
  methods: {
    // 打开弹框完毕回调
    handleOpened() {
      console.log("开始人脸识别");
      this.playVideo();
    },
    // 关闭弹框回调
    handleClose() {
      this.isVisible = false;
      if (this.isVisible == false) {
        this.close();
        this.$emit("handleDiaLogClose", this.imgUrl);
      }
    },
    // 导航栏阴影问题 组件联动 (true, false)
    changeNavBarShadow(val) {
      var dom = document.getElementById("content-area");
      if (dom) {
        if (val) {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .concat(" ")
              .concat("dialog-show-overlay")
          );
        } else {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .replace(" ", "")
              .replace("dialog-show-overlay", "")
          );
        }
      }
    },
    // 调用摄像头
    playVideo() {
      this.img = this.$refs.refImg;

      this.canvas = this.$refs.refCanvas;
      this.context = this.canvas.getContext("2d");
      this.canvas1 = this.$refs.refCanvas1;
      this.context1 = this.canvas1.getContext("2d");
      
      this.tracker = new window.tracking.ObjectTracker("face");
      this.tracker.setInitialScale(4);
      this.tracker.setStepSize(2);
      this.tracker.setEdgesDensity(0.1);

      // 开始追踪
      try {

        window.tracking.track("#video", this.tracker, { camera: true });
        this.tracker.on("track", this.handleTracked);

        this.video = this.$refs.refVideo;
        video.addEventListener('canplay', this.handleVideo);

      } catch (e) {
          console.log(e)
          this.scanTip = "访问用户媒体失败，请重试"
      }
    
    },
    // 视频
    handleVideo(event) {
      this.canvas.width = event.target.clientWidth;
      this.canvas.height = event.target.clientHeight;
      this.canvas1.width = event.target.clientWidth;
      this.canvas1.height = event.target.clientHeight;
      this.img.style.opacity = 1;
    }, 
    // 开始人脸识别
    handleTracked(event) {

      // 绘制中心圆的路径
      if(this.a) {
        this.context1.beginPath();
        this.context1.lineWidth = 2;
        this.context1.strokeStyle = 'red';
        this.context1.arc(300, 221, 150, 0, Math.PI * 2, false);
        this.context1.stroke();
        // this.animationCircle(this.canvas1, this.context1, 300, 221, 122, 0);
        this.a = false;
      } 

      if (!this.tipFlag) {

        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);

        // console.log(this.recognitionResult);
        // console.log(this.isGoReg(this.recognitionResult));
        
        if (event.data.length === 0) {

          if (!this.isGoReg(this.recognitionResult)) {

            // this.scanTip = "未识别到人脸";
            this.scanTip = "请靠近中心识别区域～";

          } else {

            // 绿效果
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.context1.beginPath();
            this.context1.lineWidth = 2;
            this.context1.strokeStyle = 'green';
            this.context1.arc(300, 221, 150, 0, Math.PI * 2, false);
            this.context1.stroke();
            this.a = true;

          }
       
        // } else if (event.data.length > 1 && !this.isGoReg(this.recognitionResult)) {

          // this.scanTip = "识别到多张人脸";

        } else {

          [event.data[0]].forEach((rect) =>  {
           
            let arr = [{x: rect.x ,y: rect.y}, {x: rect.x + rect.width ,y: rect.y}, {x: rect.x ,y: rect.y + rect.height}, {x: rect.x + rect.width ,y: rect.y + rect.height}]

            let bolArr = arr.map( item => {
              let distance = this.pointDist([item, {x:300, y:221}])
              return distance <= 150
            })

            if (bolArr.includes(false)) {

              this.scanTip = "请靠近中心识别区域～";

              this.recognitionResult = [];

            } else {

              this.scanTip = "识别成功，正在拍照，请勿乱动~";

              this.recognitionResult.push(true)

              // 绿效果
              this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
              this.context1.beginPath();
              this.context1.lineWidth = 2;
              this.context1.strokeStyle = 'green';
              this.context1.arc(300, 221, 150, 0, Math.PI * 2, false);
              this.context1.stroke();
              this.a = true;

            }
          });

          // [event.data[0]].forEach((rect) =>  {
          //   this.context.font = "12px Arial";
          //   this.context.strokeText("检测到人脸", rect.x, rect.y, [100]);
          //   this.context.strokeStyle = "#eb652e";
          //   this.context.strokeRect(rect.x, rect.y, rect.width, rect.height);
          // });

          // 3秒后拍照，仅拍一次
          if (!this.flag) {
            let n = this.collectNum(this.recognitionResult) * 100 || 0;
            this.scanTip = `正在采集人脸样本: ${n}`;
          }

          if (!this.flag && this.isGoTackPhoto(this.recognitionResult)) {

            // 绿效果
            this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.context1.beginPath();
            this.context1.lineWidth = 2;
            this.context1.strokeStyle = 'green';
            this.context1.arc(300, 221, 150, 0, Math.PI * 2, false);
            this.context1.stroke();
            this.a = false;

            // 开启
            this.tipFlag = true

            this.scanTip = '拍照中...'
            this.flag = true

            var num = 0
            var timer = setInterval(() => {
              this.scanTip = `倒计时: ${[3,2,1][num]}秒`;

              num ++;
            
              if (num >= 4) {
                this.tackPhoto(event);
                clearInterval(timer);
              }
            }, 1000);
          }

        }
      }
    },
    // 判断亮点之间的距离 [{x:0,y:0},{x:1,y:1}]
    pointDist(arr){
        var p1=arr[0];
        var p2=arr[1];
        var a = p2.x-p1.x;
        var b = p2.y-p1.y;
        return Math.sqrt(a*a+b*b);
    },
    // 判断是否可以继续识别
    isGoReg(arr){
      let numTrue = this.times(arr, true);
      let numFalse = this.times(arr, false);
      // console.log(numTrue, numFalse)
      return numTrue > numFalse
    },
    // 判断是否可以拍照(numTrue 检测次数)
    isGoTackPhoto(arr){
      if (!this.isGoReg(arr)) return false;
      let numTrue = this.times(arr, true);
      return numTrue >= 1
    },
    // 返回采集样本数
    collectNum(arr) {
      if (!this.isGoReg(arr)) return false;
      let numTrue = this.times(arr, true);
      return numTrue;
    },
    times(arr, m){
      var times = 0; //m是数组bai中的元素，dutimes用来统计出现的次数
      for(var i=0; i<arr.length; i++){
        if(arr[i]== m){
          times++;
        }
      }
      return times;
    },
    // 拍照
    tackPhoto(event) {
      // console.log(event.data[0]);
      let x = event.data[0].x - 50
      let y = event.data[0].y - 50
      let w = this.reviseWidth(event.data[0].width + 100);
      let h = event.data[0].height + 100
      // 绘制画布
      this.canvas2 = this.$refs.refCanvas2;
      this.canvas2.width = w;
      this.canvas2.height = h;
      this.context2 = this.canvas2.getContext("2d");
      // 截图
      this.context2.drawImage(this.$refs.refVideo, x,  y, w, h, 0, 0, w, h);
      // 保存为base64格式
      this.imgUrl = this.saveAsPNG(this.canvas2)
      // 自动关闭
      this.close();
      this.handleClose();
    },
    // 保存为png,base64格式图片
    saveAsPNG(c) {
      return c.toDataURL("image/jpeg", 1);
    },
    // 关闭并清理资源
    close() {
      console.log("close");
      this.recognitionResult = [];
      this.context1.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.img.style.opacity = 0;
      this.flag = false;
      this.tipFlag = false;
      this.showContainer = false;
      this.tracker &&
        this.tracker.removeListener("track", this.handleTracked) &&
        window.tracking.track("#video", this.tracker, { camera: false });
      this.tracker = null;
      this.context = null;
      this.scanTip = "";
      if (!this.$refs["refVideo"].srcObject) return;
      let stream = this.$refs["refVideo"].srcObject;
      let tracks = stream.getTracks();
      tracks.forEach(track => { track.stop();});
      this.$refs["refVideo"].srcObject = null;
      clearTimeout(this.removePhotoID);
    },
    // 绘制动态圆
    animationCircle(c, ctx, x, y, w, h) {

      // ctx.beginPath();
      // ctx.lineWidth = 2;
      // ctx.strokeStyle = 'red';
      // ctx.arc(300, 221, 122, 0, Math.PI * 2, false);
      // ctx.stroke();


      var mW = c.width = 300;
      var mH = c.height = 300;
      var lineWidth = 5;
      var r = mW / 2; //中间位置
      var cR = r - 4 * lineWidth; //圆半径
      var startAngle = -(1 / 2 * Math.PI); //开始角度
      var endAngle = startAngle + 2 * Math.PI; //结束角度
      var xAngle = 2 * (Math.PI / 180); //偏移角度量
      var cArr = []; //圆坐标数组

      //初始化圆坐标数组
      for(var i = startAngle; i <= endAngle; i += xAngle){
        //通过sin()和cos()获取每个角度对应的坐标
        var x = r + cR * Math.cos(i);
        var y = r + cR * Math.sin(i);

        cArr.push([x, y]);
      }

      //移动到开始点
      var startPoint = cArr.shift();
      ctx.beginPath();
      ctx.moveTo(startPoint[0], startPoint[1]);

      //渲染函数
      var rander = function(){
        //画圈
        if(cArr.length){
          ctx.lineWidth = lineWidth;
          ctx.strokeStyle = '#1c86d1';    

          var tmpPoint = cArr.shift();
          ctx.lineTo(tmpPoint[0], tmpPoint[1]);

          ctx.stroke();      
        }else{
          cArr = null;
          return;
        }
      };
      // console.log(rander);
      requestAnimationFrame(rander);
    },
    // 修正截图的宽度 必须4的倍数
    reviseWidth(w) {
      let remainder = w % 4
      return w - remainder
    }
  },
  destroyed() {
    console.log("destroyed");
    this.changeNavBarShadow();
  }
};
</script>

<style lang="scss" scope="scope">
.video_customDialog {
  width: 600px;
  height: 450px;
  box-shadow: none;
  .el-dialog__header {
    padding: 0px;
    .el-dialog__headerbtn {
      z-index: 999;
      .el-dialog__close {  
        color: #ececec;
      }
    }
  }
  .el-dialog__body {
    padding: 0px !important;
  }
  .face-capture {
    position:relative; 
    width:100%;
  }
  .face-capture video, .face-capture canvas {
      width:100%; 
      height:100%;
      z-index: 2;
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }
  .face-capture canvas {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
  }
  .face-capture .img-cover {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: calc(100% - 6px);
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }
  .face-capture .rect {
      border: 2px solid #0aeb08;
      position: fixed;
      z-index: 3;
  }
  .face-capture .control-container {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 4;
      background-repeat: no-repeat;
      background-size: 100% 100%;
  }
  .face-capture .title {
      text-align: center;
      color: white;
      margin: 1.6rem auto;
      font-size: 18px;
  }
  .face-capture .close {
      width: 0.8rem;
      height: 0.8rem;
  }
}
</style>
