<!-- 
    页面 管理人员主流程
-->
<template>
  <div id="collect-people-page">
    <vx-card style="min-width: 1200px">
      <div class="collects-people-header">
        <!-- <div class="collects-device-header-btn">
          <h5>读卡器状态: {{ state ? '在线' : '离线' }}</h5>
        </div> -->
        <div class="collects-people-header-btn">
          <!-- <h5 style="padding-right: 10px; padding-top: 5px;">卡号: {{ cardNo ? cardNo : '暂无卡号' }}</h5>
          <vs-button size="small" type="relief" @click="readCard">读卡</vs-button> -->
          <vs-button size="small" type="relief" @click="refreshCollect">重新采集</vs-button>
          <!-- <vs-button size="small" type="relief" @click="makeCard">制卡</vs-button> -->
        </div>
      </div>
      <div class="collects-people-container">
        <el-row :gutter="24">
          <!-- 左边视图 -->
          <el-col :span="14" class="dashedLine">
            <h4 class="dashed-text">基础信息</h4>

            <div class="upload-container" style="display: none">
              <!-- 身份证 -->
              <div class="upload-container-1">
                <el-tooltip content="点击读取身份证" placement="top">
                  <div class="el-upload addPic" v-if="!IDUrl" @click="getIDInfo">
                    <i slot="default" class="el-icon-plus"></i>
                  </div>
                  <div class="addPic relative" v-if="IDUrl">
                    <img :src="IDUrl" class="avatar" />
                    <div class="close-postion" @click="IDUrl = ''">
                      <i class="el-icon-close"></i>
                    </div>
                  </div>
                </el-tooltip>
                <div class="el-upload__text">
                  <i class="el-icon-info"></i>
                  点击读取身份证
                </div>
              </div>

              <!-- 比对值 -->
              <div class="upload-container-4" v-show="compare_pre != ''">
                <h1>{{ compare_pre }}%</h1>
              </div>

              <div class="upload-container-5">
                <!-- 头像照 -->
                <div class="upload-container-2">
                  <el-tooltip content="点拍摄" placement="top">
                    <div class="el-upload addPic" v-if="!faceUrl" @click="getFacePic">
                      <i slot="default" class="el-icon-plus"></i>
                    </div>
                    <div class="addPic relative" v-if="faceUrl">
                      <img :src="faceUrl" class="avatar" />
                      <div class="close-postion" @click="faceUrl = ''">
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                  </el-tooltip>
                  <div class="el-upload__text">
                    <i class="el-icon-info"></i>
                    点击拍摄照片
                  </div>
                </div>

                <!-- 生活照 -->
                <div class="upload-container-3">
                  <el-tooltip content="摘下眼睛,点拍摄" placement="top">
                    <div class="el-upload addPic" v-if="!liveUrl" @click="getLivePic">
                      <i slot="default" class="el-icon-plus"></i>
                    </div>
                    <div class="addPic relative" v-if="liveUrl">
                      <img :src="liveUrl" class="avatar" />
                      <div class="close-postion" @click="liveUrl = ''">
                        <i class="el-icon-close"></i>
                      </div>
                    </div>
                  </el-tooltip>
                  <div class="el-upload__text">
                    <i class="el-icon-info"></i>
                    请摘下眼镜
                  </div>
                </div>
              </div>
            </div>

            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" label-position="top">
              <el-row :gutter="24">
                <el-col :span="8">
                  <!-- 登陆表单认证(姓名) -->
                  <el-form-item label="姓名" prop="peopleName">
                    <el-input v-model="form.peopleName" prefix-icon="el-icon-user" placeholder="姓名不能为空"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(出生日期) -->
                  <el-form-item label="出生日期" prop="birthDate">
                    <el-date-picker class="w-100" v-model="form.birthDate" type="date" placeholder="出生日期" :picker-options="pickerOptions"></el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(性别) -->
                  <el-form-item label="性别" prop="genderCode">
                    <el-radio v-model="form.genderCode" label="1">男</el-radio>
                    <el-radio v-model="form.genderCode" label="2">女</el-radio>
                    <el-radio v-model="form.genderCode" label="99">保密</el-radio>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="8">
                  <!-- 登陆表单认证(国家) -->
                  <el-form-item label="国家" prop="nationality">
                    <el-select v-model="form.nationality" filterable placeholder="请选择" class="w-100">
                      <el-option v-for="item in nationalityArr" :key="item.index" :label="item.name" :value="item.name" placeholder="请选择国家"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(民族) -->
                  <el-form-item label="民族" prop="nationCode">
                    <el-select v-model="form.nationCode" filterable placeholder="请选择" class="w-100">
                      <el-option v-for="item in nationCodeArr" :key="item.index" :label="item" :value="item" placeholder="请选择民族"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(籍贯) -->
                  <el-form-item label="籍贯" prop="originCode">
                    <el-select v-model="form.originCode" filterable :filter-method="filterOriginArr" placeholder="请选择" class="w-100" v-el-select-loadmore="loadmore">
                      <el-option v-for="item in originCodeOptions.currentArr" :key="item.index" :label="item.title" :value="item.title" placeholder="请选择籍贯"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="8">
                  <!-- 登陆表单认证(政治面貌) -->
                  <el-form-item label="政治面貌" prop="politicalCode">
                    <el-select v-model="form.politicalCode" placeholder="请选择" class="w-100">
                      <el-option v-for="item in politicalCodeArr" :key="item.index" :label="item.name" :value="item.name" placeholder="请选择政治面貌"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(手机号码) -->
                  <el-form-item label="手机号码" prop="phoneNo">
                    <el-input v-model="form.phoneNo" prefix-icon="el-icon-lock" placeholder="请输入手机号码"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(学历) -->
                  <el-form-item label="学历" prop="educationCode">
                    <el-select v-model="form.educationCode" placeholder="请选择" class="w-100">
                      <el-option v-for="item in educationCodeArr" :key="item.index" :label="item.name" :value="item.name" placeholder="请选择学历"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="8">
                  <!-- 登陆表单认证(婚姻状态) -->
                  <el-form-item label="婚姻状态" prop="maritalStatusCode">
                    <el-select v-model="form.maritalStatusCode" placeholder="请选择" class="w-100">
                      <el-option v-for="item in maritalStatusCodeArr" :key="item.index" :label="item.name" :value="item.name" placeholder="请选择婚姻状态"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(证件类型) -->
                  <el-form-item label="证件类型" prop="credentialType">
                    <el-select v-model="form.credentialType" placeholder="请选择" class="w-100">
                      <el-option v-for="item in credentialTypeArr" :key="item.index" :label="item.name" :value="item.code" placeholder="请选择证件类型"></el-option>
                    </el-select>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(证件号码) -->
                  <el-form-item label="证件号码" prop="credentialNo">
                    <el-input v-model="form.credentialNo" prefix-icon="el-icon-edit-outline" placeholder="请输入证件号码"></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="8">
                  <!-- 登陆表单认证(证件有效期) -->
                  <el-form-item label="证件有效期" prop="credentialValidity">
                    <el-date-picker class="w-100" v-model="form.credentialValidity" type="date" placeholder="证件有效期" :picker-options="pickerOptions_1"></el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(发证单位) -->
                  <el-form-item label="发证单位" prop="residenceAddress">
                    <el-input v-model="form.residenceAddress" prefix-icon="el-icon-edit-outline" placeholder="发证单位"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="8">
                  <!-- 登陆表单认证(户籍行政区域) -->
                  <el-form-item label="户籍行政区域">
                    <el-cascader clearable v-if="reFresh" ref="cascader" :placeholder="form.domiclleAddress || '请选择户籍行政区域'" class="w-full" :props="cascader" @change="handleCascaderChange"></el-cascader>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row :gutter="24">
                <el-col :span="16">
                  <!-- 登陆表单认证(户籍地址) -->
                  <el-form-item label="户籍地址" prop="domiclleDetailAddress">
                    <el-input v-model="form.domiclleDetailAddress" prefix-icon="el-icon-edit-outline" placeholder="户籍地址"></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="4" :offset="4" style="margin-top: 25px;text-align: right;">
                  <vs-button size="small" type="relief" @click="submitForm('ruleForm')">{{ houseForm.people_id ? '保存' : '保存' }}</vs-button>
                </el-col>
              </el-row>
            </el-form>
          </el-col>

          <!-- 右边视图 -->
          <el-col :span="10">
            <!-- 房视图 -->
            <div class="houseByOwn" v-show="isShowHouseByOwn">
              <div style="float: left; width: 100%;">
                <h4 style="float:left" class="dashed-text">所属房屋</h4>
                <div style="float:right" v-show="isHiddenHouseForm">
                  <el-button size="mini" type="round" icon="el-icon-caret-left" class="leftIcon icon" v-show="showLeftIcon" @click="leftClick" />
                  <el-button size="mini" type="round" icon="el-icon-caret-right" class="rightIcon icon" v-show="houseOptions.showRightIcon" @click="rightClick" />
                </div>
              </div>

              <div class="upload-container-6" @click="addHouse">
                <el-tooltip content="点击添加房屋" placement="bottom" style="float: left;">
                  <div class="el-upload addCar">
                    <i slot="default" class="el-icon-plus"></i>
                  </div>
                </el-tooltip>
                <div class="el-upload__text">
                  <i class="el-icon-info"></i>
                  点击添加房屋
                </div>
              </div>

              <div id="classifyScroll" :class="showLeftIcon ? 'active' : ''" style="float: left;" v-show="isHiddenHouseForm">
                <div class="classifys">
                  <el-card shadow="hover" v-for="(item, index) in houseOptions.classify" :key="index" :ref="`item${index}`" class="classifys-item">
                    <div class="del-icon" @click="delHoldHouse(item.ph_id)"><i class="el-icon-close"></i></div>
                    <div class="list-container" @click="editHoldHouse(item)">
                      <h5>{{ item.village_name }}</h5>
                      <p>{{ item.building_name }}</p>
                      <p>{{ item.unit_name }}</p>
                      <p>{{ item.house_no }}室</p>
                    </div>
                  </el-card>
                </div>
              </div>

              <div class="houseByOwn-form" v-show="isHiddenHouseForm && isClickHouseForm">
                <el-form :model="houseForm" :rules="rules" ref="ruleForm_1" label-width="100px" label-position="top">
                  <el-row :gutter="24">
                    <el-col :span="12">
                      <!-- 登陆表单认证(小区) -->
                      <el-form-item label="小区">
                        <!-- <el-input v-model="villageName"  prefix-icon="el-icon-user" placeholder="小区" disabled></el-input> -->
                        <el-select v-model="villageCode" placeholder="请选择" class="w-100" @change="villageChange" :disabled="isClickHouseBtn">
                          <el-option v-for="item in villageArr" :key="item.index" :label="item.space_name" :value="item.space_code" placeholder="请选择小区"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <!-- 登陆表单认证(固定电话) -->
                      <el-form-item label="固定电话" prop="telephone">
                        <el-input v-model="houseForm.telephone" prefix-icon="el-icon-user" placeholder="固定电话" :disabled="tel_disabled"></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col :span="12">
                      <!-- 登陆表单认证(楼栋) -->
                      <el-form-item label="楼栋" prop="building">
                        <el-select v-model="houseForm.building" filterable :filter-method="filterbuildingArr" :placeholder="houseFormPlaceholder.buildingName || '请选择楼栋'" class="w-100" @change="buildingChange" :disabled="isClickHouseBtn">
                          <el-option v-for="item in buildingArr" :key="item.index" :label="`${item.building_name}`" :value="item.space_code"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <!-- 登陆表单认证(住户来源) -->
                      <el-form-item label="住户来源" prop="personType">
                        <el-select v-model="houseForm.personType" placeholder="请选择住户来源" class="w-100">
                          <el-option v-for="item in personTypeArr" :key="item.index" :label="item.name" :value="item.code"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col :span="12">
                      <!-- 登陆表单认证(单元) -->
                      <el-form-item label="单元" prop="unit">
                        <el-select v-model="houseForm.unit" filterable :filter-method="filterUnitArr" :placeholder="houseFormPlaceholder.unitName || '请选择单元'" class="w-100" @change="unitChange" :disabled="isClickHouseBtn">
                          <el-option v-for="item in unitArr" :key="item.index" :label="`${item.unit_name}`" :value="item.space_code"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <!-- 登陆表单认证(与户主关系) -->
                      <el-form-item label="与户主关系" prop="relationshipWithHouseHold">
                        <el-select v-model="houseForm.relationshipWithHouseHold" placeholder="请选择" class="w-100" v-show="isChangeForRelationshipWithHouseHoldArr">
                          <el-option v-for="item in relationshipWithHouseHoldArr" :key="item.index" :label="item.name" :value="item.code" :disabled="item.disabled" placeholder="请选择与户主关系"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col :span="12">
                      <!-- 登陆表单认证(房屋) -->
                      <el-form-item label="房屋" prop="house">
                        <el-select v-model="houseForm.house" filterable :filter-method="filterHouseArr" :placeholder="houseFormPlaceholder.houseName || '请选择房屋'" class="w-100" @change="houseChange" :disabled="isClickHouseBtn">
                          <el-option v-for="item in houseArr" :key="item.index" :label="item.house_no" :value="item.house_no"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <!-- 登陆表单认证(住户类型) -->
                      <el-form-item label="住户类型" prop="houseUse">
                        <el-select v-model="houseForm.houseUse" placeholder="请选择住户类型" class="w-100">
                          <el-option v-for="item in houseUseArr" :key="item.index" :label="item.name" :value="item.code"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col :span="12">
                      <!-- 登陆表单认证(起始时间) -->
                      <el-form-item label="起始时间" required>
                        <el-date-picker style="width: 100%" v-model="houseForm.auth_start_time" type="date" placeholder="选择起始日期" :picker-options="pickerOptions_authStartTime"></el-date-picker>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <!-- 登陆表单认证(截止时间) -->
                      <div v-show="!isClickHouseBtn" class="faceEndTime_btn" v-for="item of faceEndTimeOptions" :key="item.index">
                        <span @click="faceEndimeClick(item.label)" :class="{ 'is-selected': item.active }">{{ item.label }}</span>
                      </div>
                      <el-form-item label="截止时间" required>
                        <el-date-picker style="width: 100%" v-model="houseForm.auth_expire_time" type="date" placeholder="选择截止日期"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col :span="12">
                      <!-- 登陆表单认证(开启第一呼叫) -->
                      <el-form-item label="开启呼叫" required>
                        <el-switch v-model="houseForm.main_call" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12" style="text-align: right;">
                      <vs-button style="margin-top:25px; margin-right: 10px " size="small" type="relief" @click="cancelHouse">取消</vs-button>
                      <vs-button style="margin-top:25px; float:right" size="small" type="relief" @click="submitForm('ruleForm_1')">保存</vs-button>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
            <!-- 车视图 -->
            <div class="carByOwn" v-show="isShowCarByOwn">
              <!-- <div style="float: left; width: 100%; margin-top:30px">
                <h4 style="float:left" class="dashed-text">所属车辆</h4>
                <div style="float:right"  v-show="isHiddenCarForm">
                  <el-button  size="mini" type="round" icon="el-icon-caret-left" class="leftIcon icon" v-show="showCarLeftIcon" @click="leftCarClick" />
                  <el-button  size="mini" type="round" icon="el-icon-caret-right" class="rightIcon icon" v-show="houseOptions.showRightIcon" @click="rightCarClick" />
                </div>
              </div>

              <div class="upload-container-6" @click="addCar">
                <el-tooltip content="点击添加车辆" placement="bottom" style="float: left;">
                  <div class="el-upload addCar">
                    <i slot="default" class="el-icon-plus"></i>
                  </div>  
                </el-tooltip>
                <div class="el-upload__text"><i class="el-icon-info"></i> 点击添加车辆</div>
              </div>
              
              <div id="classifyScroll1"  :class="showCarLeftIcon ? 'active' : ''" style="float: left;">
                <div class="classifys-1">
                  <el-card shadow="hover" v-for="(item, index) in carOptions.classify" :key="index" :ref="`item${index}`" class="classifys-item-1">
                    <div class="del-icon" @click="delHoldCar(item.pvrID)"><i class="el-icon-close"></i></div>
                    <div class="list-container" @click="editHoldCar(item)">
                      <h5>{{ item.vehicles_no }}</h5>
                    </div>
                  </el-card>
                </div>
              </div> -->

              <div class="houseByOwn-form" v-show="isHiddenCarForm && isClickCarForm">
                <el-form :model="carForm" :rules="rules" ref="ruleForm_2" label-width="100px" label-position="top">
                  <el-row :gutter="24">
                    <el-col :span="12">
                      <!-- 登陆表单认证(车牌号) -->
                      <el-form-item label="车牌号" prop="vehicles_no">
                        <el-input v-model="carForm.vehicles_no" prefix-icon="el-icon-user" placeholder="车牌号" :disabled="isClickCarBtn"></el-input>
                      </el-form-item>
                    </el-col>

                    <el-col :span="12">
                      <!-- 登陆表单认证(车状态) -->
                      <el-form-item label="车状态" required="">
                        <el-select v-model="carForm.relation_type" placeholder="请选择车状态" class="w-100" :disabled="isClickCarBtn">
                          <el-option v-for="item in carStatusTypeArr" :key="item.index" :label="item.name" :value="item.code"></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col :span="12">
                      <!-- 登陆表单认证(有效期) -->
                      <div v-show="!isClickCarBtn" class="faceEndTime_btn" v-for="item of carExpireTimeOptions" :key="item.index">
                        <span @click="carExpireTimeClick(item.label)" :class="{ 'is-selected': item.active }">{{ item.label }}</span>
                      </div>
                      <el-form-item label="有效期" required>
                        <el-date-picker style="width: 100%" v-model="carForm.expire_time" type="date" placeholder="选择有效期" :disabled="isClickCarBtn"></el-date-picker>
                      </el-form-item>
                    </el-col>
                  </el-row>

                  <el-row :gutter="24">
                    <el-col :span="12" :offset="12" style="text-align: right;">
                      <vs-button style="margin-top:25px; margin-right: 10px " size="small" type="relief" @click="cancelCar">取消</vs-button>
                      <vs-button style="margin-top:25px; float:right" size="small" type="relief" v-show="isShowRuleForm_2" @click="submitForm('ruleForm_2')">保存</vs-button>
                    </el-col>
                  </el-row>
                </el-form>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </vx-card>
    <!-- 弹出框 -->
    <trackDialog :dialogFormVisible="dialogFormVisible" @handleDiaLogClose="handleDiaLogClose"></trackDialog>
    <readIDDialog :dialogFormVisible="dialogFormVisible_1" @handleDiaLogClose="handleDiaLogClose_1"></readIDDialog>
    <cardProDialog :dialogFormData="cardProData" :dialogFormVisible="cardProVisible" @handleDiaLogClose="cardProClose"></cardProDialog>
    <faceProDialog :dialogFormData="faceProData" :dialogFormVisible="faceProVisible" @handleDiaLogClose="faceProClose"></faceProDialog>
    <refreshDialog :dialogFormData="refreshData" :dialogFormVisible="refreshVisible" @handleDiaLogClose="refreshClose"></refreshDialog>
  </div>
</template>

<script>
// 导入弹框
import trackDialog from './track_dialog'
import readIDDialog from './readID_dialog'
import cardProDialog from './cardPro_dialog'
import faceProDialog from './facePro_dialog'
import refreshDialog from './refresh_dialog.vue'
import nationData from '@/apis/mockData/民族.json'
import nationality from '@/apis/mockData/国家.json'

export default {
  data() {
    return {
      // 图片前缀
      prefixHost: this.$cFun.getUrlPrefix(),
      // 枚举
      relationReturnDataAll: [],
      nationalityArr: nationality,
      nationCodeArr: nationData,
      villageArr: [],
      politicalCodeArr: [],
      educationCodeArr: [],
      maritalStatusCodeArr: [],
      credentialTypeArr: [],
      buildingArr: [],
      buildingArrTemp: [],
      unitArr: [],
      unitArrTemp: [],
      houseArr: [],
      houseArrTemp: [],
      houseUseArr: [],
      personTypeArr: [],
      relationshipWithHouseHoldArr: [],
      cardTypeArr: [],
      bindingTypeArr: [],
      carStatusTypeArr: [],
      // 加载状态
      reFresh: true,
      ref_originCode: false,
      state: false,
      isChangeForRelationshipWithHouseHoldArr: true,
      isShowCarByOwn: true,
      isShowHouseByOwn: true,
      isHiddenCarForm: false,
      isClickCarForm: false,
      isClickCarBtn: false,
      isHiddenHouseForm: false,
      isClickHouseForm: false,
      isClickHouseBtn: false,
      isShowRuleForm_1: true,
      isShowRuleForm_2: true,
      dialogFormVisible: false,
      dialogFormVisible_1: false,
      cardProVisible: false,
      faceProVisible: false,
      refreshVisible: false,
      tel_disabled: true,
      car_disabled: false,
      // 卡号:
      cardNo: '',
      // 填充对象
      dialogFormData: {},
      // 填充字段
      timer: '',
      liveUrl: '',
      faceUrl: '',
      IDUrl: '',
      compare_pre: '',
      villageCode: '',
      villageName: '',
      // 表单
      form: {
        peopleName: '',
        birthDate: '',
        genderCode: '99',
        nationality: '',
        nationCode: '',
        originCode: '',
        politicalCode: '',
        phoneNo: '',
        educationCode: '',
        maritalStatusCode: '',
        credentialType: '',
        credentialNo: '',
        credentialValidity: '',
        domiclleAddress: '',
        domiclleDetailAddress: '',
        residenceAddress: '',
        faceEndTime: '',
        remarks: '',
        id_pic: '',
        head_fea: '',
        head_pic: '',
        live_fea: '',
        live_pic: ''
      },
      // 房屋表单
      houseForm: {
        ph_id: '',
        people_id: '',
        village: '',
        telephone: '',
        building: '',
        personType: '',
        main_call: false,
        unit: '',
        unit_code: '',
        relationshipWithHouseHold: '',
        house: '',
        house_code: '',
        houseUse: '',
        auth_start_time: new Date(),
        auth_expire_time: '2099-12-31 00:00:00'
      },
      // 车表单
      carForm: {
        vehicles_no: '',
        expire_time: '',
        relation_type: ''
      },
      // 房屋表单占位符
      houseFormPlaceholder: {
        placeholder: '请选择',
        buildingName: '',
        unitName: '',
        houseName: ''
      },
      // 制作卡弹窗
      cardProData: {},
      faceProData: {},
      refreshData: {},
      // 规则
      rules: {
        peopleName: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
        birthDate: [{ required: true, message: '请输入出生日期', trigger: 'blur' }],
        genderCode: [{ required: true, message: '请选择性别', trigger: 'blur' }],
        nationality: [{ required: true, message: '请选择国家', trigger: 'blur' }],
        nationCode: [{ required: true, message: '请选择民族', trigger: 'change' }],
        originCode: [{ required: true, message: '请选择籍贯', trigger: 'change' }],
        politicalCode: [{ required: false, message: '请选择政治面貌', trigger: 'change' }],
        phoneNo: [{ required: true, message: '请输入手机号', trigger: 'blur' }],
        educationCode: [{ required: true, message: '请输入学历', trigger: 'change' }],
        maritalStatusCode: [{ required: false, message: '请输入婚姻状态', trigger: 'change' }],
        credentialType: [{ required: true, message: '请选择证件类型', trigger: 'change' }],
        credentialNo: [
          { required: true, message: '请输入证件号码', trigger: 'blur' },
          { validator: this.validID, trigger: 'blur' }
        ],
        credentialValidity: [{ required: true, message: '请选择证件有效期', trigger: 'change' }],
        domiclleCode: [{ required: true, message: '请选择户籍行政区域', trigger: 'change' }],
        village: [{ required: true, message: '请输入小区', trigger: 'blur' }],
        telephone: [{ required: false, message: '请输入固定电话', trigger: 'blur' }],
        building: [{ required: true, message: '请选择楼栋', trigger: 'change' }],
        personType: [{ required: true, message: '请选择住户来源', trigger: 'change' }],
        unit: [{ required: true, message: '请选择单元', trigger: 'change' }],
        relationshipWithHouseHold: [{ required: true, message: '请选择与户主关系', trigger: 'change' }],
        house: [{ required: true, message: '请选择房间', trigger: 'change' }],
        houseUse: [{ required: true, message: '请选择住户类型', trigger: 'change' }],
        vehicles_no: [{ required: true, message: '请输入车牌号', trigger: 'blur' }]
      },
      // 省份数据
      cascader: {
        lazy: true,
        lazyLoad: this.cascaderLazyLoad,
        provinces: []
      },
      // 时间控件
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      // 有效期
      pickerOptions_1: {
        disabledDate(time) {
          return time.getTime() < Date.now()
        }
      },
      // 时间控件
      pickerOptions_authStartTime: {
        disabledDate(time) {
          return time.getTime() > Date.now()
        }
      },
      // 人脸有效期选项
      faceEndTimeOptions: [
        { label: '永久', active: true },
        { label: '一年', active: false },
        { label: '半年', active: false },
        { label: '三个月', active: false }
      ],
      // 车辆有效期选择
      carExpireTimeOptions: [
        { label: '永久', active: true },
        { label: '一年', active: false },
        { label: '半年', active: false },
        { label: '三个月', active: false }
      ],
      // 籍贯
      originCodeOptions: {
        currentArr: [],
        pageIndex: 1,
        pageSize: 20,
        totalArr: this.CityDatas
      },
      // 建房间
      houseOptions: {
        showRightIcon: true, // 是否显示右箭头
        maxClickNum: 0, // 最大点击次数
        lastLeft: 0, // 上次滑动距离
        clickNum: 0, // 点击次数
        classify: []
      },
      // 建车辆
      carOptions: {
        showRightIcon: true, // 是否显示右箭头
        maxClickNum: 0, // 最大点击次数
        lastLeft: 0, // 上次滑动距离
        clickNum: 0, // 点击次数
        classify: []
      }
    }
  },
  directives: {
    'el-select-loadmore': {
      bind(el, binding) {
        const SELECTWRAP_DOM = el.querySelector('.el-select-dropdown .el-select-dropdown__wrap')
        SELECTWRAP_DOM.addEventListener('scroll', function() {
          const condition = this.scrollHeight - this.scrollTop <= this.clientHeight
          if (condition) {
            binding.value()
          }
        })
      }
    }
  },
  watch: {
    compareFaceValue: {
      handler(newval, oldval) {
        this.IDUrl = newval.IDUrl
        if (newval.IDUrl == '') {
          this.form.id_pic = ''
        }
        this.faceUrl = newval.faceUrl
        if (newval.faceUrl == '') {
          this.form.head_pic = ''
          this.form.head_fea = ''
        }
        this.liveUrl = newval.liveUrl
        if (newval.liveUrl == '') {
          this.form.live_fea = ''
          this.form.live_pic = ''
        }
        if (newval.IDUrl != '' && newval.faceUrl != '') {
          this.compareFace()
        }
      },
      deep: true
    },
    'houseForm.house_code'(val) {
      if (this.houseForm.house !== '') {
        // 编辑的情况 依赖返回值中的mainCall
        if (this.isClickHouseBtn == false) this.getMainCall(this.houseForm.house_code)
        this.getHouseOwnerExist(this.houseForm.house_code)
        this.tel_disabled = false
      } else {
        // 当用户新增房屋按钮且还未选择房屋时: 户主默认不能选
        this.changeRelationshipWithHouseHoldArr(true)
        this.tel_disabled = true
      }
    },
    'houseForm.people_id'() {
      if (this.houseForm.people_id !== '') {
        this.isHiddenHouseForm = true
        this.isHiddenCarForm = true
      } else {
        this.isHiddenHouseForm = false
        this.isHiddenCarForm = false
      }
    },
    relationReturnDataAll(val) {
      this.politicalCodeArr = _.find(val, { using_type: 'db/people/zhengzhimianmao' }).extend
      this.educationCodeArr = _.find(val, { using_type: 'db/people/wenhuachengdu' }).extend
      this.maritalStatusCodeArr = _.find(val, { using_type: 'db/people/hunyinzhuangkuang' }).extend
      this.credentialTypeArr = _.find(val, { using_type: 'db/people/zhengjianleixing' }).extend
      this.houseUseArr = _.find(val, { using_type: 'db/people/reside_mode' }).extend
      this.personTypeArr = _.find(val, { using_type: 'db/people/person_type' }).extend
      this.cardTypeArr = _.find(val, { using_type: 'db/card/card_type' }).extend
      this.bindingTypeArr = _.find(val, { using_type: 'db/card/binding_type' }).extend
      // 车类型废除
      // this.carStatusTypeArr = _.find(val, { 'using_type': 'db/people/car_status' }).extend;
      let relationshipWithHouseHoldArr = _.find(val, { using_type: 'db/people/relation_with_house_master' }).extend
      this.relationshipWithHouseHoldArr = _.map(relationshipWithHouseHoldArr, item => {
        item.name == '户主' ? (item.disabled = true) : (item.disabled = false)
        return item
      })
    },
    '$route.params.peopleID'(val) {
      if (!val) {
        window.location.reload()
      } else {
        this.init()
      }
    },
    'form.credentialNo'(val) {
      this.validID('', val, async callback => {
        if (!callback) {
          // 优先判断身份证是否录入
          let peopleID = await this.getInfoByCertificateNo(val)
          if (peopleID && peopleID != this.$route.params.peopleID) {
            // console.log('peopleID:',peopleID)
            this.refreshData = Object.assign({ peopleID: peopleID })
            this.refreshVisible = true
          }
          // 自动复数值
          if (val.length == 18 && this.form.credentialType == '2000') {
            // 出生日期
            this.form.birthDate = this.IdCard(val, 1)
            // 性别
            let sexName = this.IdCard(val, 2)
            if (sexName == '男') {
              this.form.genderCode = '1'
            } else if (sexName == '女') {
              this.form.genderCode = '2'
            }
          }
        }
      })
    }
  },
  computed: {
    showLeftIcon() {
      return this.houseOptions.clickNum > 0
    },
    showCarLeftIcon() {
      return this.carOptions.clickNum > 0
    },
    compareFaceValue() {
      const { IDUrl, faceUrl, liveUrl } = this
      return { IDUrl, faceUrl, liveUrl }
    }
  },
  mounted() {
    this.timer = setInterval(this.readDeviceState, 15000)
  },
  methods: {
    // 重新采集
    refreshCollect() {
      console.log('重新采集')
      if (!this.$route.params.peopleID) {
        this.$router.go(0)
      } else {
        this.$router.push({ name: 'peopleFlowMain' })
      }
    },
    // 人脸调整
    makeFace() {
      if (this.form.credentialNo && this.villageCode && this.houseForm.people_id) {
        this.faceProVisible = true
        this.cardProData = Object.assign({
          villageCode: this.villageCode,
          credentialNo: this.form.credentialNo,
          peopleID: this.houseForm.people_id
        })
      } else {
        this.$message({
          message: '数据正在加载,请稍后打开',
          type: 'warning',
          duration: 1500,
          offset: 100
        })
      }
    },
    // 制卡
    makeCard() {
      if (this.form.credentialNo && this.villageCode && this.houseForm.people_id) {
        this.cardProVisible = true
        this.cardProData = Object.assign({
          villageCode: this.villageCode,
          credentialNo: this.form.credentialNo,
          peopleID: this.houseForm.people_id
        })
      } else {
        this.$message({
          message: '请先填写人员和房屋信息,稍后打开',
          type: 'warning',
          duration: 1500,
          offset: 100
        })
      }
    },
    // 点击右箭头
    rightClick() {
      if (this.houseOptions.clickNum < this.houseOptions.classify.length - 1) {
        let width = document.querySelectorAll('.classifys-item')[this.houseOptions.clickNum].offsetWidth
        let lastItemOffsetLeft = document.getElementsByClassName('classifys-item')[this.houseOptions.classify.length - 1].offsetLeft
        let lookWidth = document.getElementById('classifyScroll').clientWidth
        if (lastItemOffsetLeft > lookWidth) {
          // 滚动距离（元素的magin-left值） = 负的它自己的长度 + 上一次滑动的距离
          document.getElementsByClassName('classifys')[0].style.marginLeft = `${-width + this.houseOptions.lastLeft}px`
          this.houseOptions.lastLeft = -width + this.houseOptions.lastLeft
          this.houseOptions.clickNum += 1
          this.houseOptions.maxClickNum = this.houseOptions.clickNum
        }
        this.houseOptions.showRightIcon = lastItemOffsetLeft > lookWidth + width
      }
    },
    // 点击左箭头（右侧滚动）
    leftClick() {
      if (this.houseOptions.clickNum > 0) {
        let width = document.querySelectorAll('.classifys-item')[this.houseOptions.clickNum - 1].offsetWidth
        // 滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
        document.getElementsByClassName('classifys')[0].style.marginLeft = `${this.houseOptions.lastLeft + width}px`
        this.houseOptions.lastLeft = width + this.houseOptions.lastLeft
        this.houseOptions.clickNum--
        if (this.houseOptions.clickNum < this.houseOptions.maxClickNum) {
          this.houseOptions.showRightIcon = true
        }
      }
    },
    // 点击车辆右箭头
    rightCarClick() {
      if (this.carOptions.clickNum < this.carOptions.classify.length - 1) {
        let width = document.querySelectorAll('.classifys-item-1')[this.carOptions.clickNum].offsetWidth
        let lastItemOffsetLeft = document.getElementsByClassName('classifys-item-1')[this.carOptions.classify.length - 1].offsetLeft
        let lookWidth = document.getElementById('classifyScroll1').clientWidth
        if (lastItemOffsetLeft > lookWidth) {
          // 滚动距离（元素的magin-left值） = 负的它自己的长度 + 上一次滑动的距离
          document.getElementsByClassName('classifys-1')[0].style.marginLeft = `${-width + this.carOptions.lastLeft}px`
          this.carOptions.lastLeft = -width + this.carOptions.lastLeft
          this.carOptions.clickNum += 1
          this.carOptions.maxClickNum = this.carOptions.clickNum
        }
        this.carOptions.showRightIcon = lastItemOffsetLeft > lookWidth + width
      }
    },
    // 点击车辆左箭头（右侧滚动）
    leftCarClick() {
      if (this.carOptions.clickNum > 0) {
        let width = document.querySelectorAll('.classifys-item-1')[this.carOptions.clickNum - 1].offsetWidth
        // 滚动距离（元素的magin-left值） = 它自己的长度 + 上一次滑动的距离
        document.getElementsByClassName('classifys-1')[0].style.marginLeft = `${this.carOptions.lastLeft + width}px`
        this.carOptions.lastLeft = width + this.carOptions.lastLeft
        this.carOptions.clickNum--
        if (this.carOptions.clickNum < this.carOptions.maxClickNum) {
          this.carOptions.showRightIcon = true
        }
      }
    },
    // 拍摄生活照弹框
    getLivePic() {
      //var ishttps = 'https:' == document.location.protocol ? true: false;
      // if (ishttps)  {
      this.dialogFormVisible = true
      this.clickPic = 'live'
      // 缩小侧边栏
      if (!this.$store.state.reduceButton) this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
      // } else {
      //   this.$cFun.showNotify(this.$vs.notify, "warning", "暂不支持http协议");
      // }
    },
    // 拍摄脸部照弹框
    getFacePic() {
      // var ishttps = 'https:' == document.location.protocol ? true: false;
      // if (ishttps)  {
      this.dialogFormVisible = true
      this.clickPic = 'face'
      // 缩小侧边栏
      if (!this.$store.state.reduceButton) this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
      // } else {
      //   this.$cFun.showNotify(this.$vs.notify, "warning", "暂不支持http协议");
      // }
    },
    // 打开身份证
    getIDInfo() {
      if (this.state) {
        this.dialogFormVisible_1 = true
      } else {
        this.$cFun.showNotify(this.$vs.notify, 'warning', '设备未在线, 请检测')
      }
    },
    // 切换人脸有效期
    faceEndimeClick(value) {
      let arr = []
      this.faceEndTimeOptions.map(item => {
        if (item['label'] === value) {
          item['active'] = true
          arr.push(item)
        } else {
          item['active'] = false
          arr.push(item)
        }
      })
      this.faceEndTimeOptions = arr
      // 赋值
      let date = new Date()
      if (value == '三个月') this.houseForm.auth_expire_time = date.setMonth(date.getMonth() + 3)
      if (value == '半年') this.houseForm.auth_expire_time = date.setMonth(date.getMonth() + 6)
      if (value == '一年') this.houseForm.auth_expire_time = date.setMonth(date.getMonth() + 12)
      if (value == '永久') this.houseForm.auth_expire_time = '2099-12-31 00:00:00'
    },
    // 切换车辆有效期
    carExpireTimeClick(value) {
      let arr = []
      this.carExpireTimeOptions.map(item => {
        if (item['label'] === value) {
          item['active'] = true
          arr.push(item)
        } else {
          item['active'] = false
          arr.push(item)
        }
      })
      this.carExpireTimeOptions = arr
      // 赋值
      let date = new Date()
      if (value == '三个月') this.carForm.expire_time = date.setMonth(date.getMonth() + 3)
      if (value == '半年') this.carForm.expire_time = date.setMonth(date.getMonth() + 6)
      if (value == '一年') this.carForm.expire_time = date.setMonth(date.getMonth() + 12)
      if (value == '永久') this.carForm.expire_time = '2099-12-31 00:00:00'
    },
    // 取消房屋触发按钮
    cancelHouse() {
      this.isClickHouseForm = false
      this.isShowRuleForm_1 = false
      this.isClickHouseBtn = true

      this.isShowCarByOwn = true
      this.isShowHouseByOwn = true
      this.emptyHouseForm()
    },
    // 取消车辆触发按钮
    cancelCar() {
      this.isHiddenCarForm = false
      this.isClickCarForm = false
      this.isClickCarBtn = false

      this.isShowCarByOwn = true
      this.isShowHouseByOwn = true
      this.emptyCarForm()
    },
    // 房间
    houseChange(val) {
      let obj = _.find(this.houseArr, { house_no: val })
      this.houseForm.telephone = obj.telephone
      this.houseForm.house_code = obj.space_code
    },
    // 籍贯搜索
    filterOriginArr(val) {
      let newOriginArr = _.cloneDeep(this.originCodeOptions.totalArr)
      this.form.originCode = val
      if (val) {
        //val存在
        this.originCodeOptions.currentArr = newOriginArr.filter(item => {
          if (item.title.includes(val)) {
            return true
          }
        })
      } else {
        this.loadmore()
      }
    },
    // 楼栋搜索
    filterbuildingArr(val) {
      let newOriginArr = _.cloneDeep(this.buildingArrTemp)
      this.houseForm.building = val
      this.houseForm.unit = ''
      this.houseForm.house = ''
      this.houseArr = []
      this.houseArrTemp = []
      this.unitArr = []
      this.unitArrTemp = []
      if (val) {
        //val存在
        this.buildingArr = newOriginArr.filter(item => {
          if (item.building_name.includes(val)) {
            return true
          }
        })
      } else {
        this.buildingArr = this.buildingArrTemp
      }
    },
    // 单元搜索
    filterUnitArr(val) {
      let newOriginArr = _.cloneDeep(this.unitArrTemp)
      this.houseForm.unit = val
      this.houseForm.house = ''
      this.houseArr = []
      this.houseArrTemp = []
      if (val) {
        //val存在
        this.unitArr = newOriginArr.filter(item => {
          if (item.unit_name.includes(val)) {
            return true
          }
        })
      } else {
        this.unitArr = this.unitArrTemp
      }
    },
    // 房间搜索
    filterHouseArr(val) {
      let newOriginArr = _.cloneDeep(this.houseArrTemp)
      this.houseForm.house = val
      if (val) {
        //val存在
        this.houseArr = newOriginArr.filter(item => {
          if (item.house_no.includes(val)) {
            return true
          }
        })
      } else {
        this.houseArr = this.houseArrTemp
      }
    },
    // 编辑房屋
    editHoldHouse(item) {
      this.isShowCarByOwn = false
      this.isShowHouseByOwn = true
      this.isShowRuleForm_1 = false
      this.isClickHouseForm = true
      this.isClickHouseBtn = true
      ;(this.houseFormPlaceholder = {
        buildingName: item.building_name,
        unitName: item.unit_name,
        houseName: item.house_no
      }),
        (this.houseForm = {
          people_id: item.people_id,
          village: item.village_code,
          telephone: item.telephone,
          building: item.building_name,
          personType: item.person_type,
          unit: item.unit_name,
          unit_code: item.unit_code,
          relationshipWithHouseHold: item.relation_with_house_master,
          house: item.house_no,
          house_code: item.house_code,
          houseUse: item.reside_mode,
          auth_start_time: item.auth_start_time,
          auth_expire_time: item.auth_expire_time,
          main_call: item.call_order_is_on == 1 ? true : false,
          ph_id: item.ph_id || ''
        })
    },
    // 编辑车辆
    editHoldCar(item) {
      this.isShowCarByOwn = true
      this.isShowHouseByOwn = true
      this.isHiddenCarForm = true
      this.isClickCarForm = true
      this.isShowRuleForm_2 = false
      this.isClickCarBtn = true
      this.carForm = {
        vehicles_no: item.vehicles_no,
        expire_time: item.expire_date,
        relation_type: item.relation_type
      }
    },
    // 懒加载触发籍贯数据
    loadmore() {
      // if (this.form.originCode == "" || this.form.originCode == "其他地区") {
      this.originCodeOptions.pageIndex++
      this.getCityData()
      // } else {

      // }
    },
    // 省市区三级联动 变化时
    handleCascaderChange(value) {
      let arrLen = value.length
      // 当前选中的label
      if (arrLen > 0) {
        let pathLabels = this.$refs['cascader'].getCheckedNodes()[0].pathLabels
        let address = pathLabels.join(' / ')
        console.log(address)
        this.form.domiclleAddress = address
      } else {
        this.form.domiclleAddress = ''
      }
    },
    // 清空房屋表单
    emptyHouseForm() {
      try {
        this.houseFormPlaceholder = {
          buildingName: '',
          unitName: '',
          houseName: ''
        }

        this.houseForm['ph_id'] = ''
        this.houseForm['personType'] = ''
        this.houseForm['main_call'] = false
        this.houseForm['relationshipWithHouseHold'] = ''
        this.houseForm['houseUse'] = ''
        this.houseForm['auth_start_time'] = new Date()
        this.houseForm['auth_expire_time'] = '2099-12-31 00:00:00'

        this.houseArr = []
        this.houseArrTemp = []
        this.unitArr = []
        this.unitArrTemp = []

        this.$refs.ruleForm_1.resetFields()
      } catch (e) {}
    },
    // 清空车辆
    emptyCarForm() {
      try {
        this.carForm['vehicles_no'] = ''
        this.carForm['relation_type'] = 1
        this.carForm['expire_time'] = '2099-12-31 00:00:00'

        this.$refs.ruleForm_2.resetFields()
      } catch (e) {}
    },
    // 制卡关闭回调
    cardProClose() {
      this.cardProVisible = false
    },
    // 人脸关闭回调
    faceProClose() {
      this.faceProVisible = false
    },
    // 图片base64或Url转换成可上传的数据
    changeDataByBase64orUrl(data) {
      data = data.replace('data:image/jpeg;base64,', '')
      data = data.replace('data:image/png;base64,', '')
      return data
    },
    // 修改与户主关系枚举
    changeRelationshipWithHouseHoldArr(isExist) {
      if (this.relationshipWithHouseHoldArr.length == 0) return false
      let findOwnKey = _.findIndex(this.relationshipWithHouseHoldArr, { name: '户主' })
      let tempArr = this.relationshipWithHouseHoldArr
      // 如果本人就是户主, 这个条件不启动
      //console.log(isExist)
      if (isExist === true) {
        // console.log('有户主')
        tempArr[findOwnKey]['disabled'] = true
        if (this.houseForm.relationshipWithHouseHold == 1) {
          // console.log('户主是本人')
          tempArr[findOwnKey]['disabled'] = false
        }
      } else {
        // console.log('无户主')
        tempArr[findOwnKey]['disabled'] = false
        this.houseForm['relationshipWithHouseHold'] = 1
      }
      this.isChangeForRelationshipWithHouseHoldArr = false
      this.relationshipWithHouseHoldArr = tempArr
      this.isChangeForRelationshipWithHouseHoldArr = true
    },
    // 身份证号码为15位或者18位，15位时全为数字，18位前17位为数字，最后一位是校验位，可能为数字或字符X
    validID(rule, value, callback) {
      if (this.form.credentialType != '2000') callback()
      let reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
      if (reg.test(value)) {
        callback()
      } else {
        callback(new Error('身份证号码填写不正确'))
      }
    },
    // 读卡
    async readCard() {
      // 调用设备使是否在线
      let isOnline = await this.deviceIsOnline()
      if (!isOnline) return false
      // 读卡
      let [isReadCardOk, cardType, cardID] = await this.readCardNum()
      if (!isReadCardOk) return false
      console.log(cardType, cardID)
      // let cardID = "B7AD0064"
      let peopleID = await this.getPeopleIDByCardNo(cardID, this.villageCode)
      if (peopleID != '') {
        this.$router.push({ name: 'peopleFlowMain', params: { peopleID: peopleID, cardID: cardID } })
      }
    },
    // 设备在线
    async deviceIsOnline() {
      // try {
      //   let params = {}
      //   let result = await this.$apis.ODC("读取设备状态", params);
      //   if (result == 0) {
      //     return true
      //   } else {
      //     this.$cFun.showNotify(this.$vs.notify, "warning", '读卡设备不在线');
      //     return false
      //   }
      // } catch (err) {
      //   this.$cFun.showNotify(this.$vs.notify, "danger", '读卡设备异常');
      //   return false
      // }
    },
    // 读卡号
    async readCardNum() {
      try {
        let params = {}
        let result = await this.$apis.ODC('读卡号', params)
        result = JSON.parse(result) || {}
        if (result && result.cardid != '' && (result.cardtype == 'NFC' || result.cardtype == 'IC') && result.cardtype != 'Unknown') {
          let cardType = result.cardtype
          let cardID = result.cardid
          return [true, cardType, cardID]
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', '未发现卡')
          return [true, '', '']
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', '读卡异常')
        return [true, '', '']
      }
    },
    // 根据卡号和小区编号获取peopleId
    async getPeopleIDByCardNo(cardID, village_code) {
      try {
        let params = new Object()
        params = {
          card_no: cardID,
          village_code: village_code
        }
        let result = await this.$apis.ODC('通过卡号获取人员信息', params)
        if (this.$apis.isOk(result)) {
          return result.people_id
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
          return ''
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
        return ''
      }
    },
    // 添加房屋触发按钮
    async addHouse() {
      let isVerif = this.houseForm.people_id ? true : await this.submitForm('ruleForm')
      if (isVerif) {
        this.isClickHouseForm = true
        this.isShowRuleForm_1 = true
        this.isClickHouseBtn = false
        this.emptyHouseForm()

        this.houseForm['house_code'] = ''
        this.houseForm['ph_id'] = ''
        this.houseForm['personType'] = 1
        this.houseForm['relationshipWithHouseHold'] = 99
        this.houseForm['houseUse'] = '0'
        this.houseForm['telephone'] = ''
        this.houseForm.auth_start_time = new Date()
        this.houseForm.auth_expire_time = '2099-12-31 00:00:00'

        this.cancelCar()

        this.isShowCarByOwn = false
        this.isShowHouseByOwn = true
      }
    },
    // 添加车辆触发按钮
    async addCar() {
      let isVerif = await this.submitForm('ruleForm')
      if (isVerif) {
        this.isHiddenCarForm = true
        this.isClickCarForm = true
        this.isClickCarBtn = false
        this.isShowRuleForm_2 = true

        this.isShowCarByOwn = true
        this.isShowHouseByOwn = true

        this.carForm['vehicles_no'] = ''
        this.carForm['relation_type'] = 1
        this.carForm['expire_time'] = '2099-12-31 00:00:00'

        this.cancelHouse()
      }
    },
    // refresh callback
    async refreshClose(callback) {
      this.refreshVisible = false
      this.cancelHouse()
      this.$router.push({ name: 'peopleFlowMain', params: { peopleID: callback.peopleID } })
    },
    // 照相机关闭回调
    async handleDiaLogClose(img) {
      //缩小侧边栏
      this.dialogFormVisible = false
      if (!this.$store.state.reduceButton) this.$store.commit('TOGGLE_REDUCE_BUTTON', true)
      // 上传数据
      if (this.clickPic == 'face') {
        if (img == '') return false
        // this.faceUrl = img;
        // 上传特征图片
        let head_fea = await this.feaUpload(img, 'fea')
        if (head_fea) {
          this.faceUrl = img
          this.form.head_pic = await this.pictureUpload(img, 'people')
          this.form.head_fea = head_fea
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'danger', '人脸图像质量不符合要求,请重新拍摄')
        }
      } else {
        if (img == '') return false
        // this.liveUrl =  img;
        // 上传特征图片
        let live_fea = await this.feaUpload(img, 'fea')
        if (live_fea) {
          this.liveUrl = img
          this.form.live_pic = await this.pictureUpload(img, 'people')
          this.form.live_fea = live_fea
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'danger', '人脸图像质量不符合要求,请重新拍摄')
        }
      }
    },
    // 身份证关闭回调
    async handleDiaLogClose_1(res) {
      this.dialogFormVisible_1 = false
      if (!res.IdImg) return false
      // 有返回
      this.IDUrl = `data:image/png;base64,${res.IdImg}`
      this.form.peopleName = res.Name
      this.form.domiclleDetailAddress = res.Address
      this.form.birthDate = res.Birthday.length === 8 ? `${res.Birthday.substring(0, 4)}-${res.Birthday.substring(4, 6)}-${res.Birthday.substring(6, 8)}` : ''
      this.form.residenceAddress = res.Department
      this.form.credentialValidity = res.ExpireDate.length === 8 ? `${res.ExpireDate.substring(0, 4)}-${res.ExpireDate.substring(4, 6)}-${res.ExpireDate.substring(6, 8)}` : ''
      this.form.genderCode = res.Gender == '男' ? '1' : '2' //"男"
      this.form.credentialType = 2000
      this.form.credentialNo = res.IDNo
      this.form.nationCode = res.Nation.includes('族') ? res.Nation : `${res.Nation}族` //"汉"

      // 上传身份证图片
      this.form.id_pic = await this.pictureUpload(this.IDUrl, 'people', `${res.Name}_${res.IDNo}`)
    },
    // 表单验证
    async submitForm(formName) {
      let el = event.currentTarget
      let res = null
      this.$refs[formName].validate(async valid => {
        if (valid) {
          console.log('可提交')
          if (formName === 'ruleForm') {
            if ((!this.$route.params.peopleID && this.$route.params.peopleID == '') || (!this.houseForm.people_id && this.houseForm.people_id == '')) {
              this.addPeople()
            } else {
              this.editPeople()
            }
          }
          if (formName === 'ruleForm_1') {
            if (!this.houseForm.ph_id || this.houseForm.ph_id == '') {
              this.addPeopleHouse()
            } else {
              this.editPeopleHouse()
            }
          }
          if (formName === 'ruleForm_2') {
            this.addPeopleCar()
          }
          res = true
        } else {
          res = false
          return false
        }
      })
      // 关闭loadiing
      setTimeout(() => {
        this.$cFun.closeDomLoad(this.$vs.loading, el)
      }, 1000)
      // 返回结果
      return res
    },
    // 根据身份证获取人员信息
    async getInfoByCertificateNo(certificate_no) {
      try {
        let params = { certificate_no: certificate_no }
        let result = await this.$apis.ODC('根据身份证获取人员信息', params)
        if (this.$apis.isOk(result)) {
          return result.peopleID.people_id
        } else {
          return ''
          // this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        return ''
        // this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
    },
    // 保存人车信息
    async addPeopleCar() {
      try {
        let params = new Object()
        params = {
          people_id: this.houseForm.people_id,
          vehicles_no: this.carForm.vehicles_no,
          relation_type: this.carForm.relation_type,
          expire_time: this.carForm.expire_time
        }
        let result = await this.$apis.ODC('插入人车关系', params)
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
          this.isClickCarForm = false
          this.emptyCarForm()
          await this.getVehiclesByPeopleID()
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 保存人房信息
    async addPeopleHouse() {
      // 验证房屋数据是否存在=> 因模糊搜索导致而额外
      let validate = false
      this.houseArr.filter(item => {
        if (item.space_code.includes(this.houseForm.house_code)) {
          validate = true
        }
      })

      if (!validate) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', '房屋编号异常,请重新勾选')
        return false
      }

      try {
        let params = new Object()
        params = {
          unit_code: this.houseForm.unit,
          house_code: this.houseForm.house_code,
          house_no: this.houseForm.house,
          telephone: this.houseForm.telephone,
          people_id: this.houseForm.people_id,
          relation_with_house_master: this.houseForm.relationshipWithHouseHold,
          person_type: this.houseForm.personType,
          reside_mode: String(this.houseForm.houseUse),
          auth_start_time: this.$cFun.formatTime(this.houseForm.auth_start_time),
          auth_expire_time: this.$cFun.formatTime(this.houseForm.auth_expire_time),
          main_call: this.houseForm.main_call
        }
        let result = await this.$apis.ODC('保存人房关系', params)
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
          this.isClickHouseForm = false
          this.isShowCarByOwn = true
          this.emptyHouseForm()
          await this.getPeopleHouseInfo()
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 编辑人房信息
    async editPeopleHouse() {
      try {
        let params = new Object()
        params = {
          ph_id: this.houseForm.ph_id,
          unit_code: this.houseForm.unit_code,
          house_code: this.houseForm.house_code,
          house_no: this.houseForm.house,
          telephone: this.houseForm.telephone,
          people_id: this.houseForm.people_id,
          relation_with_house_master: this.houseForm.relationshipWithHouseHold,
          person_type: this.houseForm.personType,
          reside_mode: String(this.houseForm.houseUse),
          auth_start_time: this.$cFun.formatTime(this.houseForm.auth_start_time),
          auth_expire_time: this.$cFun.formatTime(this.houseForm.auth_expire_time),
          main_call: this.houseForm.main_call
        }
        let result = await this.$apis.ODC('编辑人房关系', params)
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
          this.isClickHouseForm = false
          this.isShowCarByOwn = true
          this.emptyHouseForm()
          await this.getPeopleHouseInfo()
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 保存人员信息
    async addPeople() {
      try {
        let params = new Object()
        params['people'] = {
          people_id: this.houseForm.people_id,
          certificate_no: this.form.credentialNo,
          people_name: this.form.peopleName,
          mobile_phone: this.form.phoneNo,
          people_type: '',
          certificate_type: String(this.form.credentialType),
          certificate_validity: this.$cFun.formatTime(this.form.credentialValidity),
          sex: Number(this.form.genderCode),
          nation: this.form.nationCode,
          birth_date: this.$cFun.formatTime(this.form.birthDate),
          origin: this.form.originCode,
          domiclle_detail_address: this.form.domiclleDetailAddress,
          domiclle_address: this.form.domiclleAddress,
          residence_detail_address: '',
          residence_address: this.form.residenceAddress,
          education: this.form.educationCode,
          political: this.form.politicalCode,
          marital_status: this.form.maritalStatusCode,
          nationality: this.form.nationality,
          id_pic: this.form.id_pic,
          head_fea: this.form.head_fea,
          head_pic: this.form.head_pic,
          live_fea: this.form.live_fea,
          live_pic: this.form.live_pic
        }
        let result = await this.$apis.ODC('新增人员', params)
        if (this.$apis.isOk(result)) {
          // 获取添加人后的PeopleID
          this.houseForm.people_id = result.peopleID
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 编辑人员信息
    async editPeople() {
      try {
        let params = new Object()
        params['people'] = {
          people_id: this.houseForm.people_id,
          certificate_no: this.form.credentialNo,
          people_name: this.form.peopleName,
          mobile_phone: this.form.phoneNo,
          people_type: '',
          certificate_type: String(this.form.credentialType),
          certificate_validity: this.$cFun.formatTime(this.form.credentialValidity),
          sex: Number(this.form.genderCode),
          nation: this.form.nationCode,
          birth_date: this.$cFun.formatTime(this.form.birthDate),
          origin: this.form.originCode,
          domiclle_detail_address: this.form.domiclleDetailAddress,
          domiclle_address: this.form.domiclleAddress,
          residence_detail_address: '',
          residence_address: this.form.residenceAddress,
          education: this.form.educationCode,
          political: this.form.politicalCode,
          marital_status: this.form.maritalStatusCode,
          nationality: this.form.nationality,
          id_pic: this.form.id_pic,
          head_fea: this.form.head_fea,
          head_pic: this.form.head_pic,
          live_fea: this.form.live_fea,
          live_pic: this.form.live_pic
        }
        let result = await this.$apis.ODC('更新人员', params)
        if (this.$apis.isOk(result)) {
          // 获取添加人后的PeopleID
          this.houseForm.people_id = result.peopleID
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 上传图片
    async pictureUpload(picData, symbol, fileName = '') {
      try {
        let params = {
          usingType: 'platform',
          fileContent: picData,
          fileName: fileName,
          symbol: symbol
        }
        let result = await this.$apis.ODC('单图片上传', params)
        if (this.$apis.isOk(result)) {
          return result.data.path
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 提取特征值
    async feaUpload(picData, symbol, fileName = '') {
      try {
        let params = {
          usingType: 'platform',
          fileContent: picData,
          fileName: fileName,
          symbol: symbol
        }
        let result = await this.$apis.ODC('特征值上传', params)
        if (this.$apis.isOk(result)) {
          return result.data.path
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
          return ''
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
        return ''
      }
    },
    // 人脸比对
    async compareFace() {
      try {
        let params = {
          Live: this.changeDataByBase64orUrl(this.faceUrl),
          ID: this.changeDataByBase64orUrl(this.IDUrl)
        }
        let result = await this.$apis.ODC('人脸比对', params)
        if (result == -1) {
          this.compare_pre = 0
        } else {
          this.compare_pre = result
        }
      } catch (err) {
        console.log(err)
        this.compare_pre = 0
      }
    },
    // 获取当前用户的社区
    async getVillageList() {
      try {
        let result = await this.$apis.ODC('获取用户区域权限', {})
        if (this.$apis.isOk(result)) {
          this.villageArr = result.data
          // this.villageSelect = this.$store.state.auth.space.space_code || "";
        }
      } catch (err) {
        console.error(err.message ? err.message : err)
      }
    },
    // 读取设备状态
    async readDeviceState() {
      // try {
      //   let params = {}
      //   let result = await this.$apis.ODC("读取设备状态", params);
      //   if (result == 0) {
      //     this.state = true
      //   } else {
      //     this.state = false
      //   }
      // } catch (err) {
      //   this.state = false
      // }
    },
    // 获取楼栋列表
    async getBuildingList() {
      try {
        let params = {
          villageCode: this.villageCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取楼栋列表', params)
        if (this.$apis.isOk(result)) {
          this.buildingArr = result.data.buildingList
          this.buildingArrTemp = result.data.buildingList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取单元列表
    async getUnitList(buildingCode) {
      try {
        let params = {
          buildingCode: buildingCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取单元列表', params)
        if (this.$apis.isOk(result)) {
          return result.data.unitList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取房间列表
    async getHouseList(unitCode) {
      try {
        let params = {
          unitCode: unitCode,
          pageNum: 1,
          pageSize: 999
        }
        let result = await this.$apis.ODC('获取房屋列表', params)
        if (this.$apis.isOk(result)) {
          return result.data.houseList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取人员信息
    async getPeopleInfo() {
      try {
        let params = {
          people_id: this.houseForm.people_id
        }
        let result = await this.$apis.ODC('获取人员信息', params)
        if (this.$apis.isOk(result)) {
          this.form.credentialNo = result.peopleID.certificate_no
          this.form.peopleName = result.peopleID.people_name
          this.form.phoneNo = result.peopleID.mobile_phone
          this.form.credentialType = result.peopleID.certificate_type
          this.form.credentialValidity = result.peopleID.certificate_validity
          this.form.genderCode = String(result.peopleID.sex)
          this.form.nationCode = result.peopleID.nation
          this.form.birthDate = result.peopleID.birth_date
          this.form.originCode = result.peopleID.origin
          this.form.domiclleDetailAddress = result.peopleID.domiclle_detail_address
          this.form.domiclleAddress = result.peopleID.domiclle_address
          this.form.residenceAddress = result.peopleID.residence_address
          this.form.educationCode = result.peopleID.education
          this.form.politicalCode = result.peopleID.political
          this.form.maritalStatusCode = result.peopleID.marital_status
          this.form.nationality = result.peopleID.nationality
          this.form.id_pic = result.peopleID.id_pic
          this.form.head_fea = result.peopleID.head_fea
          this.form.head_pic = result.peopleID.head_pic
          this.form.live_fea = result.peopleID.live_fea
          this.form.live_pic = result.peopleID.live_pic

          this.IDUrl = result.peopleID.id_pic ? this.prefixHost + result.peopleID.id_pic : ''
          this.faceUrl = result.peopleID.head_pic ? this.prefixHost + result.peopleID.head_pic : ''
          this.liveUrl = result.peopleID.live_pic ? this.prefixHost + result.peopleID.live_pic : ''
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取房屋是否有户主 (return true 表示有)
    async getHouseOwnerExist(house_code) {
      try {
        let result = await this.$apis.ODC('获取房屋是否有户主', { house_code: house_code })
        if (this.$apis.isOk(result)) {
          this.changeRelationshipWithHouseHoldArr(result.exist)
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取是否勾选主呼叫人
    async getMainCall(house_code) {
      try {
        let result = await this.$apis.ODC('获取是否勾选主呼叫人', { house_code: house_code })
        if (this.$apis.isOk(result)) {
          this.houseForm.main_call = result.main_call
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获取空间列表 (省市区居委)
    async getCascaderData(partentCode) {
      try {
        let result = await this.$apis.ODC('获取空间列表', { partentCode: partentCode })
        if (this.$apis.isOk(result)) {
          return result.data
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
          return []
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
        return []
      }
    },
    // 三级省市区联动 懒加载
    async cascaderLazyLoad(node, resolve) {
      var nodes = ''
      const { level, value } = node
      if (level === 0) {
        let parentList = this.cascader.provinces
        nodes = parentList.map(item => ({
          value: item.space_code,
          label: item.space_name,
          leaf: level >= 1
        }))
      } else {
        let parentList = await this.getCascaderData(value)
        nodes = parentList.map(item => ({
          value: item.space_code,
          label: item.space_name,
          leaf: level >= 1
        }))
      }
      resolve(nodes)
    },
    // 初始获取省数据
    async getProvinceList() {
      this.cascader.provinces = await this.getCascaderData('')
      // 通知组件刷新了
      this.reFresh = false
      this.$nextTick(() => {
        this.reFresh = true
      })
    },
    // 初始化通用数据
    async getRelationReturnData(usingType) {
      try {
        let params = {
          usingTypes: [usingType]
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          return result.relationList[0].extend
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 获取枚举值(全部)
    async getRelationReturnDataALL(usingTypeArr) {
      try {
        let params = {
          usingTypes: usingTypeArr
        }
        let result = await this.$apis.ODC('获取关系内容', params)
        if (this.$apis.isOk(result)) {
          return result.relationList
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 小区被选择的时候
    async villageChange(val) {
      this.villageCode = val
      this.houseForm.building = ''
      this.houseForm.unit = ''
      this.houseForm.house = ''
      this.getBuildingList()
    },
    // 楼栋被选择的时候
    async buildingChange(val) {
      console.log('buildingChange')
      this.houseForm.unit = ''
      this.houseForm.house = ''
      this.houseArr = []
      let result = await this.getUnitList(val)
      this.unitArr = result
      this.unitArrTemp = result
    },
    // 单元
    async unitChange(val) {
      this.houseForm.house = ''
      let result = await this.getHouseList(val)
      this.houseArr = result
      this.houseArrTemp = result
    },
    // 获取人员的房屋信息
    async getPeopleHouseInfo() {
      try {
        let params = {
          people_id: this.houseForm.people_id,
          village_code: _.map(this.villageArr, 'space_code')
        }
        let result = await this.$apis.ODC('获取人房关系', params)
        if (this.$apis.isOk(result)) {
          this.houseOptions.classify = result.PHList
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 获得人车关系
    async getVehiclesByPeopleID() {
      try {
        let params = {
          people_id: this.houseForm.people_id
        }
        let result = await this.$apis.ODC('获得人车关系', params)
        if (this.$apis.isOk(result)) {
          this.carOptions.classify = result.vehicles
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 删除房屋
    async delHoldHouse(item) {
      try {
        let params = {
          ph_id: item
        }
        let result = await this.$apis.ODC('删除人房关系', params)
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
          this.cancelHouse()
          await this.getPeopleHouseInfo()
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 删除车子
    async delHoldCar(item) {
      try {
        let params = {
          pvrID: item
        }
        let result = await this.$apis.ODC('删除人车关系', params)
        if (this.$apis.isOk(result)) {
          this.$cFun.showNotify(this.$vs.notify, 'success', this.$apis.getResMessage(result))
          await this.getVehiclesByPeopleID()
        } else {
          this.$cFun.showNotify(this.$vs.notify, 'warning', this.$apis.getResMessage(result))
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, 'danger', err.message ? err.message : err)
      }
    },
    // 懒加载籍贯数据
    async getCityData() {
      let num = this.originCodeOptions.pageIndex * this.originCodeOptions.pageSize
      this.originCodeOptions.currentArr = _.take(this.CityDatas, num)
    },
    // 身份证获取年月日
    IdCard(IdCard, type) {
      if (type === 1) {
        //获取出生日期
        let birthday = IdCard.substring(6, 10) + '-' + IdCard.substring(10, 12) + '-' + IdCard.substring(12, 14)
        return birthday
      }
      if (type === 2) {
        //获取性别
        if (parseInt(IdCard.substr(16, 1)) % 2 === 1) {
          return '男'
        } else {
          return '女'
        }
      }
      if (type === 3) {
        //获取年龄
        var ageDate = new Date()
        var month = ageDate.getMonth() + 1
        var day = ageDate.getDate()
        var age = ageDate.getFullYear() - IdCard.substring(6, 10) - 1
        if (IdCard.substring(10, 12) < month || (IdCard.substring(10, 12) === month && IdCard.substring(12, 14) <= day)) {
          age++
        }
        if (age <= 0) {
          age = 1
        }
        return age
      }
    },
    // INIT
    async init() {
      // 请求数据
      this.villageCode = this.$store.state.auth.space.space_code || ''
      this.villageName = this.$store.state.auth.space.space_name || ''
      // this.readDeviceState();
      this.getVillageList()
      this.getProvinceList()
      this.getCityData()
      this.getBuildingList()

      this.relationReturnDataAll = await this.getRelationReturnDataALL(['db/people/zhengzhimianmao', 'db/people/wenhuachengdu', 'db/people/hunyinzhuangkuang', 'db/people/zhengjianleixing', 'db/people/reside_mode', 'db/people/person_type', 'db/people/relation_with_house_master', 'db/card/card_type', 'db/card/binding_type', 'db/people/car_status'])

      // 获取读卡后的ID
      if (this.$route.params.cardID) {
        this.cardNo = this.$route.params.cardID
      }

      // 获取peopleID 是否存在
      if (this.$route.params.peopleID && this.$route.params.peopleID.length > 2) {
        this.houseForm.people_id = this.$route.params.peopleID
        await this.getPeopleInfo()
        await this.getPeopleHouseInfo()
        // await this.getVehiclesByPeopleID();
      } else {
        // 初始化
        this.form.credentialValidity = this.$cFun.formatTime(new Date().getTime() + 24 * 60 * 60 * 1000)
        this.form.birthDate = this.$cFun.formatTime()
        this.form.originCode = '其他地区'
        this.form.credentialType = '2000'
        this.form.maritalStatusCode = '未知'
        this.form.educationCode = ''
        // this.form.domiclleAddress = "请选择户籍行政区域";
        this.form.politicalCode = '群众'
        this.form.nationCode = '汉族'
        this.form.nationality = '中国'
      }
    }
  },
  async created() {
    await this.init()
  },
  beforeDestroy() {
    // console.log("beforeDestroy")
    clearInterval(this.timer)
  },
  components: {
    trackDialog,
    readIDDialog,
    cardProDialog,
    faceProDialog,
    refreshDialog
  }
}
</script>

<style lang="scss" scope="scope">
#collect-people-page {
  .collects-people-header {
    .collects-device-header-btn {
      float: left;
      margin-top: 8px;
    }
    .collects-people-header-btn {
      display: flex;
      justify-content: flex-end;
      button {
        margin-left: 5px;
      }
    }
  }
  .collects-people-container {
    margin-top: 15px;
    .upload-container {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      width: 100%;
      margin: 0 0 10px 0;
      .el-upload__text {
        font-size: 10px;
        margin-top: 10px;
        text-align: center;
      }
    }
    .upload-container-2 {
      float: left;
    }
    .upload-container-3 {
      float: left;
      margin-left: 25px;
    }
    .upload-container-4 {
      width: 150px;
      height: 150px;
      h1 {
        line-height: 150px;
        text-align: center;
        font-size: 20px;
      }
    }
    .upload-container-6 {
      display: flex;
      flex-direction: column;
      float: left;
      margin: 0 0 10px 0;
      .el-upload__text {
        font-size: 10px;
        margin-top: 10px;
        text-align: center;
      }
    }
    .w-100 {
      width: 100%;
    }
    .w-150 {
      width: 150px;
    }
    .dashedLine {
      border: none;
      border-right: 1px dashed #ccc !important;
      height: 100%;
    }
    .addCar {
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 150px;
      height: 150px;
      line-height: 150px;
      vertical-align: top;
    }
    .addPic {
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 150px;
      height: 150px;
      line-height: 150px;
      vertical-align: top;
      overflow: hidden;
    }
    .close-postion {
      position: absolute;
      text-align: center;
      line-height: 0;
      top: 5px;
      right: 5px;
      z-index: 99;
      cursor: pointer;
    }
    .avatar {
      width: 100%;
    }
    .dashed-text {
      margin: 0 0 10px 0;
    }
    .faceEndTime_btn {
      float: right;
      font-size: 0.7rem;
      font-weight: 600;
      line-height: 100%;
      span {
        cursor: pointer;
        border-right: 1px solid #ccc;
        padding: 0 3px;
      }
      .is-selected {
        background-color: rgba(var(--vs-primary), 1) !important;
        color: #fff;
      }
    }
    .icon {
      padding: 5px 15px !important;
    }
    .icon:focus,
    .icon:hover {
      color: rgba(var(--vs-primary), 1) !important;
      background-color: rgba(var(--vs-primary), 0.1) !important;
      border-color: rgba(var(--vs-primary), 1) !important;
    }
    .houseByOwn-form {
      display: inline-block;
      width: 100%;
    }
  }
}

// 这里是用的scss
#classifyScroll,
#classifyScroll1 {
  max-width: calc(100% - 150px);
  overflow: hidden;
  transition: all 0.3s;
  &.active {
    transition: all 0.3s;
    width: 90%;
    margin: 0 auto;
  }
  .classifys,
  .classifys-1 {
    transition: all 0.3s;
    display: flex;
    align-items: center;
    .el-card__body {
      padding: 0 !important;
    }
    &-item,
    &-item-1 {
      overflow: inherit !important;
      white-space: nowrap;
      margin: 0 0 0 20px;
      position: relative;
      .del-icon {
        position: absolute;
        text-align: center;
        width: 20px;
        height: 20px;
        top: 5px;
        right: 5px;
        z-index: 99;
        cursor: pointer;
      }
      .list-container {
        text-align: center;
        width: 150px;
        height: 150px;
        padding: 20px;
        cursor: pointer;
        h5,
        p {
          padding-top: 8px;
          overflow: hidden; //超出的文本隐藏
          text-overflow: ellipsis; //用省略号显示
          white-space: nowrap; //不换行
        }
      }
    }
  }
}
</style>
