<template>
  <el-dialog customClass="facePro_dialog"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="handleOpened"
  >

    <!-- 弹窗头 -->
    <span slot="title" class="el-dialog__title">
      <vs-avatar class="mr-3" color="primary" text="C" size="22px" />
      <h5 class="line-h-31">{{ dialogTitle }}</h5>
    </span>

    <div>

      <h6 style="font-size: 0.8rem; font-weight: 600; line-height: 100%; margin-bottom: 10px">通行权限</h6>

      <el-table  border ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column label="类型" width="120">
          <template slot-scope="scope">
            <p>{{ _.find(GroupTypeArr, { 'code': scope.row.groupType}) ? _.find(GroupTypeArr, { 'code': scope.row.groupType}).name : "" }}</p>
          </template>
        </el-table-column>
        <el-table-column prop="groupName" label="通行组" show-overflow-tooltip></el-table-column>
      </el-table>

    </div>

    <!-- 弹窗内容 -->
    <span slot="footer" class="dialog-footer">
      <vs-button  size="small"  type="relief" @click="handleClose" style="margin-right:10px">取消</vs-button>
      <vs-button  size="small"  type="relief" @click="dispatchFace">权限下发</vs-button>
    </span>

  </el-dialog>
</template>

<script>
export default {
  props: ["dialogFormVisible", "dialogFormData"],
  data() {
    return {
      dialogTitle: '人脸通行权限',
      isVisible: this.dialogFormVisible,
      isDisable: false, 
      // 渲染数据
      cardList: [],
      // 枚举
      relationReturnDataAll: [],
      GroupTypeArr: [],
      CardStatusArr: [],
      CardTypeArr: [],
      BindTypeArr: [],
      // 加载状态
      drawerLoading: false,
      
      // 提交
      form: {
        credentialNo: '',
        villageCode: '',
        peopleID: '',
        cardType: '',
        cardNo: '',
        cardAttribute: '',
        effectTime: '',
        effectYear: ''
      },
      rules: {
        cardType: [
          { required: true, message: '请输入卡类型', trigger: 'blur' }
        ],
        cardNo: [
          { required: true, message: '请输入卡号', trigger: 'blur' }
        ],
        cardAttribute: [
          { required: true, message: '请输入卡属性', trigger: 'blur' }
        ],
        effectTime: [
          { required: false, message: '请输入有效时间', trigger: 'blur' }
        ],
        effectYear: [
          { required: false, message: '请输入有效期', trigger: 'blur' }
        ]
      },
      // table
      tableData: [],
      multipleSelection: []
    };
  },
  watch: {
    // 监听父组件传回来的值 - 表单值
    dialogFormData(val) {
      //console.log("dialogFormData", val);
      this.reFresh = false;

      // 设备所需参数
      this.form = this.decodeParam(val);

      // 通知组件刷新了
      this.$nextTick(() => {
        this.reFresh = true;
      });
    },
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    },
    "relationReturnDataAll"(val) {
      let GroupTypeArr =  _.find(val, { 'using_type': 'db/pass_group/group_type' });
      let CardStatusArr =  _.find(val, { 'using_type': 'db/card/card_status' });
      let CardTypeArr = _.find(val, { 'using_type': 'db/card/card_type' }); 
      let BindTypeArr = _.find(val, { 'using_type': 'db/card/binding_type' });  
      this.CardStatusArr = CardStatusArr.extend;
      this.GroupTypeArr = GroupTypeArr.extend;
      this.CardTypeArr = CardTypeArr.extend;
      this.BindTypeArr = BindTypeArr.extend;
    }
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 打开弹框完毕回调
    async handleOpened() {
      console.log('打开弹窗')
      // 获取枚举
      this.relationReturnDataAll = await this.getRelationReturnDataALL(["db/pass_group/group_type","db/card/card_status","db/card/card_type",'db/card/binding_type']);
      // 获取卡列表
      this.getCardList(this.form.villageCode, this.form.credentialNo)
      // 获取通行权限 (1 已勾 0 可勾)
      this.getPeopleGroupList(this.form.peopleID, this.form.villageCode);
    },
    // 关闭弹框回调
    handleClose() {
      this.isVisible = false;
       if (this.isVisible == false) {
        clearInterval(this.timer);
        this.changeNavBarShadow();
        this.$emit("handleDiaLogClose", this.result);
      }
    },
    // 导航栏阴影问题 组件联动 (true, false)
    changeNavBarShadow(val) {
      var dom = document.getElementById("content-area");
      if (dom) {
        if (val) {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .concat(" ")
              .concat("dialog-show-overlay")
          );
        } else {
          dom.setAttribute(
            "class",
            dom
              .getAttribute("class")
              .replace(" ", "")
              .replace("dialog-show-overlay", "")
          );
        }
      }
    },
    // 提示框
    showNotify(state, text) {
      let title,
        color = "";
      switch (state) {
        case "success":
          title = "成功";
          color = "success";
          break;
        case "danger":
          title = "错误";
          color = "danger";
          break;
        case "warning":
          title = "警告";
          color = "warning";
          break;
      }
      this.$vs.notify({
        time: 2500,
        title: title,
        text: text,
        iconPack: "feather",
        icon: "icon-alert-circle",
        color: color
      });
    },
    // 恢复提交参数
    decodeParam(val) {
      let form = JSON.parse(JSON.stringify(this.form));
      
      form.credentialNo = val.credentialNo;
      form.villageCode = val.villageCode;
      form.peopleID = val.peopleID;

      return form;
    },
    // 获取卡列表
    async getCardList(village_code, certificate_no) {
      this.drawerLoading = true;
      try {
        let param = {
          village_code: village_code,
          certificate_no: certificate_no
        }
        let result = await this.$apis.ODC("获取卡列表", param);
        if (this.$apis.isOk(result)) {
          this.cardList = result.cardList;
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
      this.drawerLoading = false;
    },
    // 获取枚举值(全部)
    async getRelationReturnDataALL(usingTypeArr) {
      try {
        let params = {
          usingTypes: usingTypeArr
        };
        let result = await this.$apis.ODC("获取关系内容", params);
        if (this.$apis.isOk(result)) {
          return  result.relationList
        } else {
          return []
        }
      } catch (err) {
        return []
      }
    },
    // 获取用户通行组列表 (1 已勾 0 可勾)
    async getPeopleGroupList(peopleID, villageCode) {
      this.tableLoading = true;
      try {
        let param = {
          peopleID: peopleID,
          villageCode: villageCode,
          source: 1
        }
        let result = await this.$apis.ODC("获取用户通行组列表", param);
        if (this.$apis.isOk(result)) {
          this.tableData = result.data;
          setTimeout(()=> {
            this.toggleSelection(_.filter(result.data, { 'hasChoose': 1}));
          },500)
        } else {
          this.$cFun.showNotify(this.$vs.notify, "warning", this.$apis.getResMessage(result));
        }
      } catch (err) {
        this.$cFun.showNotify(this.$vs.notify, "danger", err.message ? err.message : err);
      }
      this.tableLoading = false;
    },
    // 权限下发
    async dispatchFace() {
      this.$cFun.showNotify(this.$vs.notify, "danger", '待联调');
    }
  }
};
</script>

<style lang="scss" scope="scope">
  .facePro_dialog {
    width: 50%;
    min-width: 350px;
    .el-dialog__body {
      margin-top: 30px !important;
    }
    .infinite-list {
      display: flex;
      width: 480px;
      overflow-x: auto;
      overflow-y: hidden;
      .infinite-list-item {
        float: left;
      }
    }
    .addCard{
      float: left;
      margin-right: 20px;
      background-color: #fbfdff;
      border: 1px dashed #c0ccda;
      border-radius: 6px;
      box-sizing: border-box;
      width: 150px;
      height: 120px;
      line-height: 120px;
      vertical-align: top;
    }
  }
</style>
