<template>
  <el-dialog
    customClass="readID_dialog"
    :visible.sync="isVisible"
    :destroy-on-close="true"
    :show-close="true"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @opened="handleOpened"
  >

    <div class="readIDCardContent">
      <h1>正在读取身份证<span class="loading" style="position: inherit"></span></h1>
    </div>

  </el-dialog>
</template>

<script>
// import res from './../../../../apis/mockData/身份证.json'

export default {
  props: ["dialogFormVisible"],
  data() {
    return {
      dialogTitle: "读卡",
      isVisible: this.dialogFormVisible,
      isDisable: false, 
      timer: '',
      result: ''
    };
  },
  watch: {
    // 监听父组件传回来的值 - 开关值
    dialogFormVisible(val) {
      this.$cFun.changeNavBarShadow(val);
      this.isVisible = this.dialogFormVisible;
    }
  },
  methods: {
    // 打开弹框完毕回调
    handleOpened() {
      // console.log("开始身份证识别");
      this.readIdentityCard() 
      this.timer = setInterval(() => {
        if (this.result == '') {
          this.readIdentityCard() 
        } else {
          this.handleClose()
        }
      },3000);
    },
    // 关闭弹框回调
    handleClose() {
      this.isVisible = false;
       if (this.isVisible == false) {
        clearInterval(this.timer);
        this.$cFun.changeNavBarShadow();
        this.$emit("handleDiaLogClose", this.result);
      }
    },
    // 读取身份证
    async readIdentityCard() {
      try {
        let result = await this.$apis.ODC("读身份证", {});
        if (result.IDNo) {
          this.result = result
          this.handleClose()
        }
      } catch (err) {
        this.result = ''
      }
    }
  }
};
</script>

<style lang="scss" scope="scope">
.readID_dialog {
  width: 450px;;
  height: 300px;
  box-shadow: none;
  .readIDCardContent {
    display: inline-block;
    width: 250px;
    height: 33px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -125px;
    margin-top: -17px;
  }
  .el-dialog__header {
    padding: 0px;
    .el-dialog__headerbtn {
      z-index: 999;
    }
  }
  .el-dialog__body {
    padding: 0px !important;
  }
  .loading:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis 2s infinite;
    content: "\2026";
  }
  @keyframes ellipsis {
    from {
      width: 2px;
    }
    to {
      width: 15px;
    }
  }
}
</style>
